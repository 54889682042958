<template>
  <li class="item-content item-input">
    <div class="item-inner">
      <f7-list-item v-if="field.FieldType=='checkbox'" checkbox :title="field.DisplayName" :id="'field'+field.CRMCampaignFieldId" class="c-checkbox--center" 
        :checked="fieldValue === true"
        @change="fieldValue = !fieldValue">
      </f7-list-item>

      <div v-if="field.FieldType!='checkbox' && field.FieldType!='image'" class="item-title item-label">{{field.DisplayName}}</div>

      <div v-if="field.FieldType!='checkbox'" class="item-input-wrap" :class="{'input-dropdown-wrap': field.FieldType=='select'}">
        
        <input type="text" v-if="field.FieldType=='text'" :id="'field'+field.CRMCampaignFieldId" :placeholder="field.DisplayName" :maxlength="field.MaxLength" :minlength="field.MinLength" :required="field.Required" v-model="fieldValue"/>
        
        <input type="tel" v-if="field.FieldType=='number'" :id="'field'+field.CRMCampaignFieldId" :placeholder="field.DisplayName" pattern="\d*[\.,]{0,1}\d*" :maxlength="field.MaxLength" :minlength="field.MinLength" :required="field.Required" v-model="fieldValue"/>
        
        <textarea v-if="field.FieldType=='textarea'" :id="'field'+field.CRMCampaignFieldId" :placeholder="field.DisplayName" :maxlength="field.MaxLength" :minlength="field.MinLength" :required="field.Required" v-model="fieldValue"></textarea>
        
        <select v-if="field.FieldType=='select'" :id="'field'+field.CRMCampaignFieldId" v-model="fieldValue" :required="field.Required">
          <option :value="null" disabled>Escolha uma opção</option>
          <option v-for="item in field.FieldValues" :key="item" :value="item">{{item}}</option>
        </select>
        
        <div v-if="field.FieldType=='image'" class="c-image">
          <div class="c-image-label">{{field.DisplayName}}</div>
          <div class="c-image-button">
            <div class="button-wrapper">
              <span class="label">
                <f7-icon material="add_a_photo"></f7-icon>{{fieldFName}}
              </span>
              <input type="file" 
                :name="'field'+field.CRMCampaignFieldId"  
                :id="'field'+field.CRMCampaignFieldId"  
                class="upload-box" 
                placeholder="Foto" 
                accept="image/*" 
                capture="camera" 
                @change="fileChange">
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
//import func from '../../../../vue-temp/vue-editor-bridge';
export default {
  name: 'form-field',
  data: function(){
    return {
      fieldValue: null,
      fieldFName: ""
    }
  },
  props:{ 
    field: Object
  },
  methods: {
    printValue: function(){
      var self = this;
      return self.field.DisplayName + ': ' + self.fieldValue + '\n';
    },
    getField: function(){
      var self = this;
      return { 
        CampaignFieldId: self.field.CRMCampaignFieldId,
        DisplayName: self.field.DisplayName,
        Answer: self.fieldValue
      };
    },
    resetField: function () {
      var self = this;
      self.fieldValue = '';
    },
    takePicture: function(){
      var self = this;
      navigator.camera.getPicture(self.pictureSuccess, self.pictureError, { 
        quality: 50,
        destinationType: Camera.DestinationType.FILE_URI 
      });

    },
    pictureSuccess: function(imageUri){
      var self = this;
      self.fieldValue = imageUri;
      self.fieldFName = "(1)";
    },
    pictureError: function(error){
      console.log("picture error: " + error);
    },
    fileChange: function(e){
      var self = this;
      try {
        var reader = new FileReader();
        reader.onload = (e2) => { 
          self.fieldFName = "(1)";
          self.fieldValue = e2.target.result;
        }
        reader.readAsDataURL(e.target.files[0]);
      } 
      catch (error) {
        self.fieldFName = null;
        self.fieldValue = null;
        console.log("picture error: " + error);
      }
    },
    getFile(){
      var self = this;
      return document.getElementsByName("field"+self.field.CRMCampaignFieldId)[0].files[0];
    }
  }
};
</script>

<style>
.c-checkbox--center > label.item-checkbox > .item-inner{
    display: flex;
    align-items: center;
}

.c-image{
  display: flex;
}

.c-image-label {
  display: inline-block;
  margin-top: auto;
  margin-bottom: auto;
}

.c-image-button {
  display: inline-block;
  width: 100px; 
  margin-left: auto;
}

.button-wrapper {
  position: relative;
  width: 80px;
  text-align: center;
  margin: 20% auto;
}

.button-wrapper span.label {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 100%;
  background: #00bfff;
  cursor: pointer;
  color: #fff;
  padding: 10px 0;
  text-transform:uppercase;
  font-size:12px;
}

.upload-box {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 50px;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}
</style>
