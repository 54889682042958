<template>
    <f7-navbar sliding>
        <f7-nav-left v-if="showBack" back-link="Back"></f7-nav-left>
        <div class="title"><logo/></div>
        <f7-nav-right class="c-version block-title right" style="margin-bottom: 40px;line-height: normal;zoom: 0.6;">{{title}}</f7-nav-right>
    </f7-navbar>
</template>

<script>
import { f7Navbar, f7NavLeft, f7NavTitle, f7NavRight } from 'framework7-vue';
import Logo from './logo.vue'

export default {
    components: {
        Logo,
        f7Navbar, 
        f7NavLeft, 
        f7NavTitle,
        f7NavRight
    },
    name: 'c2s-navbar',
    props:{ 
        title: String,
        showBack: Boolean
    },
}
</script>