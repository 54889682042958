<template>
  <f7-page>
    <c2snavbar :showBack="true" title="Prospects/Agentes" />
    <form class="list form-store-data" enctype="multipart/form-data" id="prospects-form">
      <!-- AGENT, PROSPECT(NIF or AgentCode) -->
      <f7-list v-if="!createCRMAgent && !createContact">
        <f7-list-item radio title="Agente" name="prospectType" 
          :checked="prospectType === 'Agente'"
          @change="changeSearch('Agente')">
        </f7-list-item>
        <f7-list-item radio title="NIF" name="prospectType" 
          :checked="prospectType === 'NIF'"
          @change="changeSearch('NIF')">
        </f7-list-item>
        <f7-list-item radio title="Prospect" name="prospectType" 
          :checked="prospectType === 'Prospect'"
          @change="changeSearch('Prospect')">
        </f7-list-item>
        <f7-list-item v-if="prospectType != null && prospectType != 'Prospect'">
          <input name="prospectCode" pattern="*\d*" v-on:keydown.enter.prevent v-on:keyup.enter="enableSearchKeyEnter" type="number" maxlength="9" :placeholder="'Insira o '+prospectType" v-model="prospectCode" >
          <f7-button class="button-fill" iconMaterial="search" v-if="hasValidProspectCode" @click="searchProspectCode"></f7-button>
        </f7-list-item>
        <f7-list-item v-if="prospectType === 'Prospect'">
          <input name="prospectCode" pattern="(P|p)*\d*" v-on:keydown.enter.prevent v-on:keyup.enter="enableSearchKeyEnter" type="text" maxlength="9" :placeholder="'Insira o '+prospectType" v-model="prospectCode" >
          <f7-button class="button-fill" iconMaterial="search" v-if="hasValidProspectCode" @click="searchProspectCode"></f7-button>
        </f7-list-item>
        <f7-list-item v-if="showSalePointList" class="input-dropdown-wrap">
          <select v-model="selectedSalePoint">
            <option :value="null" disabled>Escolha o ponto de venda</option>
            <option v-for="item in salePoints" :key="item.SalePointId" :value="item">
              {{ item.Name }}
            </option>
          </select>
        </f7-list-item>
      </f7-list>

      <!-- ENTITY PANEL  -->
      <f7-card v-if="createContact">
        <f7-card-content>
          <p><b>Nome&nbsp;</b> {{crmInfo.name}}</p>
          <p v-if="prospectType === 'Agente'"><b>Agent&nbsp;</b> {{prospectCode}}</p>
          <p v-if="selectedSalePoint"><b>Ponto de venda&nbsp;</b> {{selectedSalePoint.Name}}</p>
          <p v-if="prospectType != 'Agente'"><b>NIF&nbsp;</b> {{workingRecord.Tin}}</p>
        </f7-card-content>
      </f7-card>

      <!-- ACTION OPTIONS -->
      <f7-list v-if="crmInfo.showActions">
        <f7-list-item>
          <f7-button iconMaterial="mail" class="button-round button-fill" title="Ver contactos" @click="actionListContacts" style="width:100%">Ver contactos</f7-button>
        </f7-list-item>
        <f7-list-item v-if="selectedSalePoint">
          <f7-button iconMaterial="point_of_sale" class="button-round button-fill" title="Nova encomenda" @click="actionCreateOrder" style="width:100%">Nova encomenda</f7-button>
        </f7-list-item>
        <f7-list-item>
          <f7-button iconMaterial="photo_on_rectangle" class="button-round button-fill" title="Adicionar Imagens" @click="docFilesOpened = true" style="width:100%">Adicionar Imagens</f7-button>
        </f7-list-item>
      </f7-list>

      <!-- NEW PROSPECT FORM (CRMAgent)-->
      <crmagent-form v-if="createCRMAgent" ref="crmAgentForm" @form-save="submitCrmAgent" :crmAgentName="crmInfo.name"></crmagent-form>
      <!-- CREATE CONTACT -->
      <campaign-form v-if="createContact" ref="campaignForm" @form-save="submitCrmContact" saveText="Registar contacto"></campaign-form>
      <!-- DOCUMENT FILES -->
      <documentfiles-form 
        ref="documentfilesForm" 
        @form-save="submitDocFiles" 
        saveText="Guardar Imagens"
        :popupOpened="docFilesOpened" 
        :onClose="closeDocFiles">
      </documentfiles-form>
    </form>
  </f7-page>
</template>

<script>
/*
Workflow: 
  prospectType:check 
    -> agent 
        -> add Contact
        -> Show comments
    -> crmagent (prospect)
        -> create?
        -> add Contact
        -> Show comments
*/

import Logo from '../components/logo.vue'
import c2snavbar from '../components/c2s-navbar.vue'
import BackendApi from '../components/backend-api.js'
import CrmAgentModel from '../models/crm-agent.js'
import CrmContact from '../models/crm-contact.js'
import CampaignForm from '../components/campaign-form.vue'
import DocumentFiles from '../components/documentfiles-form.vue'
import CrmAgentForm from '../components/crmagent-form.vue'
import DialogsMixin from '../mixins/dialogs.js'
import GpsRequestParams from '../models/gps-request-params.js'

export default {
  mixins: [ DialogsMixin ],
  components: {
    c2snavbar,
    Logo,
    'campaign-form': CampaignForm,
    'crmagent-form': CrmAgentForm,
    'documentfiles-form': DocumentFiles
  },
  data: function(){
    return {
      prospectType: null,
      prospectCode: null,

      isValidTin: false,
      showSalePointList: false,
      salePoints: null,
      selectedSalePoint: null,

      latitude: this.latitude,
      longitude: this.longitude,
      message: this.message,

      createCRMAgent: false,
      submitResult: null,

      crmInfo: {
        showPanel: false,
        name: null,
        code: null,
        isAgent: false,
        isCRMAgent: false,
        id: null,
        showActions: false
      },

      workingRecord: CrmAgentModel,

      docFilesOpened: false,
    }
  },
  created: function() {
      var self = this;
      if (navigator.geolocation)
        {
        navigator.geolocation.getCurrentPosition(
            position => { 
            self.latitude = position.coords.latitude;
            self.longitude = position.coords.longitude;
            self.message = '';
            self.loaded = true;
            },
            reason => {
            self.message = reason.message;
            self.loaded = true;
            },
            { 
            enableHighAccuracy: true, 
            timeout: 10000 
            });
        }
        else
        {
        self.showDialog("Localização não iniciada!", false);
        }
  },
  computed: {
    hasValidProspectCode: function()
    {
      var self = this;
      if (self.prospectType == "NIF") {
        return self.validateNIF(self.prospectCode); 
      }
      else {
        return self.prospectCode != null;
      }
    },
    subTitle: function(){
      var self = this;
      if (!self.createCRMAgent && !self.createContact) {
        return "Pesquisar prospect";
      }
      if (self.createCRMAgent) {
        return "Registar novo prospect";
      }
      if (self.createContact) {
        return "Registar contacto";
      }
    },
    createContact: function(){
      var self = this;
      return (self.prospectType == 'Agente') ? (self.showSalePointList && self.selectedSalePoint) : self.isValidTin;
    }
  },
  methods: {
    validateNIF: function(str) {
        if (!str || str.length == 0)
        {
          return false;
        }
        let nif = str.toUpperCase();
        if (!/(PT)?([123568]\d{1}|45|7[0124579]|9[0189])(\d{7})/.test(nif)) {
            return false;
        }

        nif = nif.replace(/PT/, ''); //remove the PT part
        const checkDigit = function() {
            let c = 0;
            for (let i = 0; i < nif.length - 1; ++i) {
                c += Number(nif[i]) * (10 - i - 1);
            }
            c = 11 - (c % 11);
            return c >= 10 ? 0 : c;
        };

        return checkDigit() === Number(str.charAt(str.length - 1));
    },
    getSalePointList: function()
    {
      var self = this;
      self.showDialog('A pesquisar...', true);

      BackendApi.getSalePointList(
        {"AgentCode": self.prospectCode,"DeviceTerminalId": BackendApi.deviceId() },
        result => {
          self.salePoints = result.data.SalePointList;
          self.selectedSalePoint = null;
          
          if (!self.salePoints || self.salePoints.length == 0)
          {
            self.showDialog('Agente não encontrado!', false);
            return;
          }

          if (self.salePoints.length == 1)
          {
            self.selectedSalePoint = self.salePoints[0];
          }

          self.hideDialog();
        },
        error => {
          self.salePoints = null;
          self.selectedSalePoint = null;
          self.showDialog('Agente não encontrado!', false);
        }
      )
    },
    setCrmInfo: function(showPanel, data, isAgent)
    {
      var self = this;
      if (data != null)
      {
        self.crmInfo.showPanel = showPanel;
        self.crmInfo.name = data.Name;
        self.crmInfo.code = data.AgentCode;
        self.crmInfo.isAgent = isAgent;
        self.crmInfo.isCRMAgent = !isAgent;
        self.crmInfo.id = isAgent ? data.AgentId : data.CRMAgentId;
        self.crmInfo.showActions = true;
      } 
      else 
      {
        self.crmInfo.showPanel = false;
        self.crmInfo.name = null;
        self.crmInfo.code = null;
        self.crmInfo.isAgent = false;
        self.crmInfo.isCRMAgent = false;
        self.crmInfo.id = null;
        self.crmInfo.showActions = false;
      }

      self.workingRecord = data;
    },
    enableSearchKeyEnter : function(){ //for the Go button in the keyboard
      var self = this;
      if (self.prospectType == "NIF") {
        if( self.validateNIF(self.prospectCode)){
          self.searchProspectCode()
        }
      }
      else {
         self.searchProspectCode()
      }
    },
    searchProspectCode: function(){
      var self = this;
      self.setCrmInfo(false, null, false);
      self.createCRMAgent = false;
      self.showSalePointList = false;

      self.showDialog('A pesquisar...', true);

      if (self.prospectType == 'Agente') 
      {
        BackendApi.getAgent(
          {"AgentCode": self.prospectCode,"DeviceTerminalId": BackendApi.deviceId()},
          result => {
            if (result.data.DeviceTerminalId == null || result.data.AgentCode != self.prospectCode)
            {
              self.showDialog('Agente não encontrado!', false);
              return;
            }
            
            self.setCrmInfo(true, result.data, true);
            self.showSalePointList = true;
            self.getSalePointList();
            self.hideDialog();
          },
          error => {
            self.setCrmInfo(false, null, true);
            self.showDialog('Não foi possivel contactar a Pagaqui! '+error, false);
          }
        )
      } 
      else if(self.prospectType == 'NIF')
      {
        BackendApi.getAgentByTin({"AgentCode": self.prospectCode,"DeviceTerminalId": BackendApi.deviceId()},
          result => {
            if (result.data.DeviceTerminalId == null)
            {
              self.showDialog('O seu dispositivo não permite esta operação! Por favor, contacte o suporte.', false);
              return;
            }

            if (result.data.Exists)
            {
              self.showDialog('Este NIF já existe como Agente!', false);
              return;
            }
            BackendApi.getCRMAgent({"NIF": self.prospectCode,"DeviceTerminalId": BackendApi.deviceId()},
              result => {
                self.hideDialog();
                if (result.data.DeviceTerminalId != null && result.data.Tin != self.prospectCode)
                {
                    // novo prospect
                    self.setCrmInfo(false, result.data, false);
                    self.crmInfo.name = '';
                    self.crmInfo.id = 0;
                    self.createCRMAgent = true;
                    self.crmInfo.showActions = false;
                }
                else{ 
                  if (result.data.DeviceTerminalId != null)
                  {
                    // já existe
                    self.setCrmInfo(true, result.data, false);
                    self.isValidTin = true;
                  }
                  else
                  {
                    // device invalido
                    self.showDialog('O seu dispositivo não permite esta operação! Por favor, contacte o suporte.', false);
                  }
                }
              },
              error => {
                self.showDialog('Não foi possivel contactar a Pagaqui! '+error, false);
              }
            )
          },
          error => {
            self.showDialog('Não foi possivel contactar a Pagaqui! '+error, false);
          }
        );
      }
      else if(self.prospectType == 'Prospect')
      {
        BackendApi.getProspect({"AgentCode": self.prospectCode,"DeviceTerminalId": BackendApi.deviceId()},
          result => {
            self.hideDialog();
            if (result.data.DeviceTerminalId == null)
            {
              self.showDialog('O seu dispositivo não permite esta operação! Por favor, contacte o suporte.', false);
              return;
            }

            if (result.data.Exists)
            {
              self.showDialog('Este Prospect já existe como Agente!', false);
              return;
            }

            if (result.data.DeviceTerminalId != null && result.data.AgentCode != self.prospectCode)
            {
              // novo prospect
              self.setCrmInfo(false, result.data, false);
              self.crmInfo.name = '';
              self.crmInfo.id = 0;
              self.createCRMAgent = false;
              self.crmInfo.showActions = false;
            }
            else
            { 
              if (result.data.DeviceTerminalId != null)
              {
                // já existe
                self.setCrmInfo(true, result.data, false);
                self.isValidTin = true;
                self.crmInfo.showActions = true;
              }
              else
              {
                // device invalido
                self.showDialog('O seu dispositivo não permite esta operação! Por favor, contacte o suporte.', false);
              }
            }
          },
          error => {
            self.showDialog('Não foi possivel contactar a Pagaqui! '+error, false);
          }
        );
      }
    },
    submitCrmAgent: function(){
      var self = this;

      self.workingRecord = self.$refs.crmAgentForm.getForm();
      self.workingRecord.DeviceTerminalId = BackendApi.deviceId();
      self.workingRecord.CRMAgentId = 0;
      self.workingRecord.AgentCode = null;
      self.workingRecord.Tin = self.prospectCode;
      // self.workingRecord.Name = self.crmInfo.name;
      self.workingRecord.Active = true;
      self.workingRecord.CreatedDate = null;
      self.crmInfo.name = self.workingRecord.Name;

      self.showDialog('A enviar dados...', true);

      // insert CRMAgent
      BackendApi.setCRMAgent(self.workingRecord, result => {
          if (result.data.DeviceTerminalId != null && result.data.CRMAgentId != null && result.data.CRMAgentId != 0 )
          {
            self.workingRecord = result.data;
            self.createCRMAgent = false;
            self.showingLoader = false;
            self.isValidTin = true;
            self.hideDialog();
          } else {
            self.showDialog('Dados não aceites!', false);
          }
      },
      error => {
          self.showDialog('Não foi possivel contactar a Pagaqui! '+error, false);
      });
    },
    submitCrmContact: function(){
      var self = this;
      self.showDialog("A enviar dados...", true);

      var requestObject = CrmContact;
      requestObject.AgentId = self.crmInfo.isAgent ? self.workingRecord.AgentId : self.workingRecord.CRMAgentId;
      requestObject.SalePointId = (self.crmInfo.isAgent && self.selectedSalePoint) ? self.selectedSalePoint.SalePointId : 0;
      requestObject.IsAgent = self.crmInfo.isAgent;
      requestObject.DeviceTerminalId = BackendApi.deviceId();
      requestObject.Description = self.$refs.campaignForm.printValues();
      requestObject.CampaignInfo = self.$refs.campaignForm.getCampaignInfo();
      //Gps data
      requestObject.DeviceLatitude = self.latitude;
      requestObject.DeviceLongitude = self.longitude;
      var files = self.$refs.campaignForm.getFiles();
      
      BackendApi.saveProspectContact(requestObject,
        result => {
          if (result.data && result.data.Result == 0)
          {
            if (result.data.ErrorMessage ==""){
              self.showDialog("Os dados não foram aceites.", false);
            }
            else{
              self.showDialog(result.data.ErrorMessage, false);

            }
          }
          else
          {
            if (self.$refs.campaignForm.hasFiles())
            {
              self.submitCrmContactFiles(requestObject, result.data.Result, files);
            }
            else
            {
              self.hasSubmited = true;
              self.showDialog("Registo de contacto enviado!", false);
            }
          }
        },
        error => {
          self.showDialog("Erro ao enviar dados! Por favor, tente novamente ou contacte o suporte.", false);
        }
      );
    },
    submitCrmContactFiles: function(crmContact, contactId, files){
      var self = this;
      
      // file upload check
      if (files && files.length > 0 && contactId > 0)
      {
        // upload files!
        for(var i = 0; i < files.length; i++)
        {
          var options = new Object();
          options.fileKey = "file";
          options.fileName = "file"+i;
          options.mimeType = "image/jpeg";
          options.chunkedMode = false;
          options.params = {
            ContactId: contactId,
            IsAgent: crmContact.IsAgent,
            DeviceTerminalId: crmContact.DeviceTerminalId,
            DeviceAppVersion: BackendApi.deviceAppVersion(),
            CampaignFieldId: files[i].id,
          };

          self.showDialog("A enviar " + options.fileName, true);
          
          BackendApi.uploadFile(files[i].file, result => {
            }, 
          error => {
            //
            self.hasSubmited = false;
            self.showDialog("Falha ao submeter imagem!", false);
          }, options);
        }
        // exit
        self.hasSubmited = true;
        self.showDialog("Registo de contacto enviado!", false);
      }
      else
      {
        self.hasSubmited = true;
        self.showDialog("Registo de contacto enviado!", false);
      }
    },
    actionListContacts: function(){
      var self = this;
      this.$f7router.navigate(`/contacts?id=${self.crmInfo.id}&agent=${self.crmInfo.isAgent}&name=${self.crmInfo.name}&nif=${self.workingRecord.Tin}&agentCode=${self.workingRecord.AgentCode}`);
    },
    actionCreateOrder: function(){
      var self = this;
      this.$f7router.navigate(`/orders?agentId=${self.crmInfo.id}&agentName=${self.crmInfo.name}&agentCode=${self.workingRecord.AgentCode}&salePointId=${self.selectedSalePoint.SalePointId}&salePointName=${self.selectedSalePoint.Name}`);
    },
    changeSearch: function(searchType){
      if(this.prospectType != searchType){
        this.setCrmInfo(false, null, false);
        this.showSalePointList = false;
      }
      this.prospectType = searchType;
      this.prospectCode = null;
    },
    closeDocFiles: function(){
      this.docFilesOpened = false;
    },
    submitDocFiles: function(){
      var self = this;
      var documentfilesForm = self.$refs.documentfilesForm;

      var files = documentfilesForm.getFiles();
      if(files.length == 0){
        docFilesOpened = false;
        return;
      }

      var options = {
        fileName: "image",
        maxDimensions: {
          width: 512,
          height: 512
        },
        params: {
          ContactId: self.crmInfo.id,
          IsAgent: self.crmInfo.isAgent,
          DeviceTerminalId: BackendApi.deviceId(),
          DeviceAppVersion: BackendApi.deviceAppVersion(),
          DocumentCategory: documentfilesForm.categoryId,
          DocumentName: documentfilesForm.documentName
        }
      }

      self.showDialog('A enviar dados...', true);

      // insert CRMAgent
      BackendApi.uploadAgentFiles(files, result => {
          if (result.data.Result == true )
          {
            documentfilesForm.onFormClosed();
            self.hideDialog();
          } else {
            self.showDialog('Dados não aceites!', false);
          }
      },
      error => {
          self.showDialog('Não foi possivel contactar a Pagaqui! '+error, false);
      }, options);
    },
  }
};
</script>
