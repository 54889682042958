<template>
    <f7-popup class="demo-popup" :opened="popupOpened" @popup:closed="onClose()">
      <f7-page>
        <f7-navbar :title="selectedEvent.Title">
          <f7-nav-right>
            <f7-link popup-close>Fechar</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-card title="Agente">
          <f7-card-content>
            <p class="u-zeromargin"><b>Nome Contato:&nbsp;</b>{{selectedEvent.AgentContactName}}</p>
            <p class="u-zeromargin" v-if="selectedEvent.AgentEmail != null"><b>Email:&nbsp;</b> {{selectedEvent.AgentEmail}}</p>
            <p class="u-zeromargin" v-if="selectedEvent.AgentPhone1 != null"><b>Phone 1:&nbsp;</b> {{selectedEvent.AgentPhone1}}</p>
            <p class="u-zeromargin" v-if="selectedEvent.AgentPhone2 != null"><b>Phone 2:&nbsp;</b> {{selectedEvent.AgentPhone2}}</p>
            <p class="u-zeromargin"><b>Morada:</b></p>
            <div class="u-textwrapper">{{selectedEvent.AgentAddress}}</div>
          </f7-card-content>
        </f7-card>

        <f7-card title="Ponto de venda">
          <f7-card-content>
            <p class="u-zeromargin"><b>Nome:&nbsp;</b>{{selectedEvent.SalePointName}}</p>
            <p class="u-zeromargin"><b>Nome Contato:&nbsp;</b>{{selectedEvent.SalePointContactName}}</p>
            <p class="u-zeromargin" v-if="selectedEvent.SalePointEmail != null"><b>Email:&nbsp;</b> {{selectedEvent.SalePointEmail}}</p>
            <p class="u-zeromargin" v-if="selectedEvent.SalePointPhone1 != null"><b>Phone 1:&nbsp;</b> {{selectedEvent.SalePointPhone1}}</p>
            <p class="u-zeromargin" v-if="selectedEvent.SalePointPhone2 != null"><b>Phone 2:&nbsp;</b> {{selectedEvent.SalePointPhone2}}</p>
            <p class="u-zeromargin"><b>Morada:</b></p>
            <div class="u-textwrapper">{{selectedEvent.SalePointAddress}}</div>
          </f7-card-content>
          <f7-card-footer v-if="selectedEvent.SalePointAddressURL != null">
            <f7-link text="Abrir GPS (Waze)" @click="openURL(selectedEvent.SalePointAddressURL)" target="_system" />
          </f7-card-footer>
        </f7-card>

        <f7-card v-if="selectedEvent.Position != null">
          <f7-card-content class="u-nopadding">
            <google-map id="map" ref="Map" class="c-map"
             :center="selectedEvent.Position"
             :options="mapOptions"
             :zoom="mapOptions.zoom"
             :minZoom="mapOptions.minZoom"
             :heading="mapOptions.heading"
             :mapTypeId="mapOptions.mapTypeId"
            >
              <google-map-marker :position="selectedEvent.Position" :title="selectedEvent.SalePointName"></google-map-marker>
            </google-map>
          </f7-card-content>
        </f7-card>

        <f7-card v-if="hasComments">
          <f7-card-content>
            <p><b>Comentários</b></p>
            <div class="u-textwrapper">{{selectedEvent.Comments}}</div>
          </f7-card-content>
        </f7-card>
      </f7-page>
    </f7-popup>
</template>

<script>
export default {
  name: 'calendar-detail',
  data: function(){
    return {
      mapOptions: {
        zoom: 15,
        minZoom: 7,
        mapTypeId: 'terrain',
        heading: 0
      },
    }
  },
  props:{ 
    selectedEvent: Object,
    popupOpened: false,
    onClose: Function,
  },
  computed:{
    hasComments: function(){
      if(this.selectedEvent.Comments == null) return false;
      if(this.selectedEvent.Comments.length == 0) return false;
      return true;
    }
  },
  methods:{
    openURL: function(url){
      window.open(encodeURI(url), '_system', 'location=yes');
    }
  }
}
</script>

<style scoped>
    .u-nopadding{
        padding: 0em !important;
    }

    .u-zeromargin{
        margin: 0em 0;
    }

    .c-map{
        width:100%; 
        height:20em;
    }

    .u-textwrapper{
        width:100%;
        overflow-y: auto;
        white-space: pre-wrap;
    }
</style>