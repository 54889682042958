export default {
    data: function(){
        return {
            hasSubmited: false
        }
    },
    methods: {
        hideDialog: function()
        {
            this.$f7.dialog.close();
        },
        showDialog: function(text, showLoader)
        {
            this.$f7.dialog.close();

            if (showLoader)
            {
                this.$f7.dialog.preloader(text);
            } 
            else 
            {
                var diag = this.$f7.dialog.create({
                    title: '',
                    text: text,
                    closeByBackdropClick: false,
                    buttons: [{text: 'Fechar'}],
                    verticalButtons: false,
                    onClick: this.closePage
                });
                diag.open();
            }
        },
        closePage: function(){
            if (this.hasSubmited)
            {
                this.$f7router.navigate('/');
            }
        }
    }
}