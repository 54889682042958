<template>
    <f7-popup class="demo-popup" :opened="popupOpened" @popup:closed="onClose()">
      <f7-page v-if="selectedTaxCondition != null">
        <f7-navbar :title="selectedTaxCondition.Title">
          <f7-nav-right>
            <f7-link popup-close>Fechar</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <div class="data-table card">
          <table>
            <tbody>
              <tr>
                <td class="label-cell">MCC</td><td class="label">
                  {{selectedTaxCondition.MCCVisaMC}}
                  <span v-if="selectedTaxCondition.MCCName != null">&nbsp;-&nbsp;{{selectedTaxCondition.MCCName}}</span>
                </td>
              </tr>
              <tr><td class="label-cell"><b>Custo de Activação (&euro;)</b></td><td class="label">{{selectedTaxCondition.ActivationValue}}</td></tr>
              <tr><td class="label-cell"><b>Custo Mensal (&euro;)</b></td><td class="label">{{selectedTaxCondition.MonthValue}}</td></tr>
              <tr><td class="label-cell"><b>Comissão conta externa (&euro;)</b></td><td class="label">{{selectedTaxCondition.ExternalComission}}</td></tr>
              <tr><td class="label-cell"><b>Valor mínimo transação (&euro;)</b></td><td class="label">{{selectedTaxCondition.MinimumFee}}</td></tr>
              <tr v-if="selectedTaxCondition.IsBlended"><td class="label-cell"><b>Blended Fee</b></td><td class="label">{{selectedTaxCondition.BlendedValue}}</td></tr>
            </tbody>
          </table>
        </div>

        <div class="data-table card" v-if="!selectedTaxCondition.IsBlended">
          <table>
            <thead>
              <tr>
                <th class="label-cell">Operações Nacionais</th>
                <th class="label">&euro;</th>
                <th class="label">%</th>
                <th class="label">+I.F</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="label-cell">TSC Cartões Débito Consumer</td>
                <td class="label">{{selectedTaxCondition.NatTSCDebitCard}}</td>
                <td class="label">{{selectedTaxCondition.NatTSCDebitCardFixed}}</td>
                <td class="label"><f7-checkbox disabled :checked="selectedTaxCondition.NatTSCDebitCardIF" /></td>
              </tr>
              <tr>
                <td class="label-cell">TSC Cartões Crédito Consumer</td>
                <td class="label">{{selectedTaxCondition.NatTSCCreditCard}}</td>
                <td class="label">{{selectedTaxCondition.NatTSCCreditCardFixed}}</td>
                <td class="label"><f7-checkbox disabled :checked="selectedTaxCondition.NatTSCCreditCardIF" /></td>
              </tr>
              <tr>
                <td class="label-cell">TSC Cartões Débito Empresa</td>
                <td class="label">{{selectedTaxCondition.NatTSCCompanyDebitCard}}</td>
                <td class="label">{{selectedTaxCondition.NatTSCCompanyDebitCardFixed}}</td>
                <td class="label"><f7-checkbox disabled :checked="selectedTaxCondition.NatTSCCompanyDebitCardIF" /></td>
              </tr>
              <tr>
                <td class="label-cell">TSC Cartões Crédito Empresa</td>
                <td class="label">{{selectedTaxCondition.NatTSCCompanyCreditCard}}</td>
                <td class="label">{{selectedTaxCondition.NatTSCCompanyCreditCardFixed}}</td>
                <td class="label"><f7-checkbox disabled :checked="selectedTaxCondition.NatTSCCompanyCreditCardIF" /></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="data-table card" v-if="!selectedTaxCondition.IsBlended">
          <table>
            <thead>
              <tr>
                <th class="label-cell">Operações Intracomunitárias <br/>VISA e Mastercard</th>
                <th class="label">&euro;</th>
                <th class="label">%</th>
                <th class="label">+I.F</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="label-cell">TSC Cartões Débito Consumer</td>
                <td class="label">{{selectedTaxCondition.VmTSCDebitCard}}</td>
                <td class="label">{{selectedTaxCondition.VmTSCDebitCardFixed}}</td>
                <td class="label"><f7-checkbox disabled :checked="selectedTaxCondition.VmTSCDebitCardIF" /></td>
              </tr>
              <tr>
                <td class="label-cell">TSC Cartões Crédito Consumer</td>
                <td class="label">{{selectedTaxCondition.VmTSCCreditCard}}</td>
                <td class="label">{{selectedTaxCondition.VmTSCCreditCardFixed}}</td>
                <td class="label"><f7-checkbox disabled :checked="selectedTaxCondition.VmTSCCreditCardIF" /></td>
              </tr>
              <tr>
                <td class="label-cell">TSC Cartões Débito Empresa</td>
                <td class="label">{{selectedTaxCondition.VmTSCCompanyDebitCard}}</td>
                <td class="label">{{selectedTaxCondition.VmTSCCompanyDebitCardFixed}}</td>
                <td class="label"><f7-checkbox disabled :checked="selectedTaxCondition.VmTSCCompanyDebitCardIF" /></td>
              </tr>
              <tr>
                <td class="label-cell">TSC Cartões Crédito Empresa</td>
                <td class="label">{{selectedTaxCondition.VmTSCCompanyCreditCard}}</td>
                <td class="label">{{selectedTaxCondition.VmTSCCompanyCreditCardFixed}}</td>
                <td class="label"><f7-checkbox disabled :checked="selectedTaxCondition.VmTSCCompanyCreditCardIF" /></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="data-table card" v-if="!selectedTaxCondition.IsBlended">
          <table>
            <thead>
              <tr>
                <th class="label-cell">Operações NEEA</th>
                <th class="label">&euro;</th>
                <th class="label">%</th>
                <th class="label">+I.F</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="label-cell">Cartões Débito Consumer NEEA</td>
                <td class="label">{{selectedTaxCondition.ConsumerNEEADebitCard}}</td>
                <td class="label">{{selectedTaxCondition.ConsumerNEEADebitCardFixed}}</td>
                <td class="label"><f7-checkbox disabled :checked="selectedTaxCondition.ConsumerNEEADebitCardIF" /></td>
              </tr>
              <tr>
                <td class="label-cell">Cartões Crédito Consumer NEEA</td>
                <td class="label">{{selectedTaxCondition.ConsumerNEEACreditCard}}</td>
                <td class="label">{{selectedTaxCondition.ConsumerNEEACreditCardFixed}}</td>
                <td class="label"><f7-checkbox disabled :checked="selectedTaxCondition.ConsumerNEEACreditCardIF" /></td>
              </tr>
              <tr>
                <td class="label-cell">Cartões Débito Empresa NEEA</td>
                <td class="label">{{selectedTaxCondition.CompanyNEEADebitCard}}</td>
                <td class="label">{{selectedTaxCondition.CompanyNEEADebitCardFixed}}</td>
                <td class="label"><f7-checkbox disabled :checked="selectedTaxCondition.CompanyNEEADebitCardIF" /></td>
              </tr>
              <tr>
                <td class="label-cell">Cartões Crédito Empresa NEEA</td>
                <td class="label">{{selectedTaxCondition.CompanyNEEACreditCard}}</td>
                <td class="label">{{selectedTaxCondition.CompanyNEEACreditCardFixed}}</td>
                <td class="label"><f7-checkbox disabled :checked="selectedTaxCondition.CompanyNEEACreditCardIF" /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </f7-page>
    </f7-popup>
</template>

<script>
export default {
  name: 'taxcondition',
  data: function(){
    return {
      
    }
  },
  props:{ 
    selectedTaxCondition: Object,
    popupOpened: Boolean,
    onClose: Function,
  },
}
</script>

<style scoped>
    .u-nopadding{
        padding: 0em !important;
    }

    .u-zeromargin{
        margin: 0em 0;
    }

    .c-map{
        width:100%; 
        height:20em;
    }

    .u-textwrapper{
        width:100%;
        overflow-y: auto;
        white-space: pre-wrap;
    }
</style>