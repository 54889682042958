<template>
  <div>
    <!-- SELECTOR -->
    <f7-card>
      <f7-card-content class="item-inner input-dropdown-wrap">
        <select name="campaignSelector" v-model="campaignId">
          <option :value="null" disabled>Escolha a campanha</option>
          <option v-for="item in campaigns" :key="item.CRMCampaignId" :value="item.CRMCampaignId">{{item.Name}}</option>
        </select>
        <f7-button class="button-round button-fill" small iconMaterial="search" v-if="campaignId > 0" @click="loadCampaignFields"></f7-button>
      </f7-card-content>
    </f7-card>
    <!-- CAMPAIGN FIELDS -->
    <ul v-if="campaignFields && campaignFields.length > 0 && campaignId > 0">
      <form-field v-for="item in campaignFields" :key="item.fieldId" v-bind:field="item" ref="fields" />
      <li class="item-content item-input">
        <div class="item-inner">
          <f7-button 
            class="button-fill button-round" 
            iconMaterial="cloud_upload" 
            @click="$emit('form-save')">&nbsp;{{saveText}}</f7-button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import BackendApi from '../components/backend-api.js'
import FormField from '../components/form-field.vue'

export default {
  name: "campaign-form",
  components: {
    'form-field': FormField
  },
  props: {
    saveText: String
  },
  data: function(){
    return {
      campaignId: null,
      campaignFields: [],
      campaigns: [],
    }
  },
  created: function()
  {
    var self = this;
    self.loadCampaigns();
  },
  methods: {
    hasFiles: function(){
      var self = this;
      var fields = self.$refs.fields;
      for(var i = 0; i < fields.length; i++)
      {
        if (fields[i].field.FieldType=='image')
        {
          return true;
        }
      }
      return false;
    },
    getFiles: function(){
      var self = this;
      var fields = self.$refs.fields;
      var result = new Array();

      if (!fields || fields.length == 0)
      {
        return result;
      }
      
      for(var i = 0; i < fields.length; i++)
      {
        if (fields[i].field.FieldType!='image')
        {
          continue;
        }

        if (fields[i].fieldValue && fields[i].fieldValue.length > 0)
          result.push({id: fields[i].field.CRMCampaignFieldId, file: fields[i].getFile()});
      }

      return result;
    },
    printValues: function()
    {
      var self = this;
      var fields = self.$refs.fields;
      var result = '';
      if (!fields || fields.length == 0)
      {
        return result;
      }

      for(var i = 0; i < fields.length; i++)
      {
        if (fields[i].field.FieldType == 'image')
        {
          continue;
        }
        result = result + fields[i].printValue();
      }

      return result;
    },
    getCampaignInfo: function()
    {
      var self = this;
      var fields = self.$refs.fields;
      var result = { CampaignId: self.campaignId, FieldAnswers: [] };

      if (!fields || fields.length == 0)
      {
        return result;
      }

      for(var i = 0; i < fields.length; i++)
      {
        if (fields[i].field.FieldType == 'image')
        {
          continue;
        }
        result.FieldAnswers.push(fields[i].getField());
      }

      return result;
    },
    loadCampaigns: function(){
      var self = this;
      BackendApi.getCampaignList(
        {"DeviceTerminalId": BackendApi.deviceId()},
        result => {
          if (result.data && result.data.Campaigns.length > 0)
          {
            self.campaigns = result.data.Campaigns;
          }
          else
          {
            self.campaigns = [];
          }
        },
        error => {
          self.campaigns = [];
        }
      )
    },
    loadCampaignFields: function(){
      var self = this;
      BackendApi.getCampaignFields(
        {"CRMCampaignId": self.campaignId, "DeviceTerminalId": BackendApi.deviceId()},
        result => {
          if (result.data && result.data.CampaignFields.length > 0)
          {
            self.campaignFields = result.data.CampaignFields; 
            //clean the value from the fields
            var fields = self.$refs.fields;
            if (fields && fields.length != 0)
            {
              for(var i = 0; i < fields.length; i++)
              {
                fields[i].resetField();
              }
            }
          }
          else
          {
            self.campaignFields = [];
          }
        },
        error => {
          self.campaignFields = [];
        }
      )
    }
  }
};
</script>
