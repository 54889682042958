<template>
  <f7-popup class="demo-popup" :opened="popupOpened" @popup:closed="onClose()">
    <f7-page v-if="selectedTaxCondition != null">
      <f7-navbar :title="selectedTaxCondition.Title" />
      <div class="data-table card">
        <table>
          <tbody>
            <tr><td class="label-cell"><b>Custo de Activação (&euro;)</b></td><td class="label"><input class="taxfield" v-model="selectedTaxCondition.ActivationValue" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td></tr>
            <tr><td class="label-cell"><b>Custo Mensal (&euro;)</b></td><td class="label"><input class="taxfield" v-model="selectedTaxCondition.MonthValue" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td></tr>
            <tr><td class="label-cell"><b>Comissão conta externa (&euro;)</b></td><td class="label"><input class="taxfield" v-model="selectedTaxCondition.ExternalComission" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td></tr>
            <tr><td class="label-cell"><b>Valor mínimo transação (&euro;)</b></td><td class="label"><input class="taxfield" v-model="selectedTaxCondition.MinimumFee" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td></tr>
            <tr v-if="selectedTaxCondition.IsBlended"><td class="label-cell"><b>Blended Fee</b></td><td class="label"><input class="taxfield" v-model="selectedTaxCondition.BlendedValue" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="6" minlength="1" required validate /></td></tr>
          </tbody>
        </table>
      </div>

      <div class="data-table card" v-if="!selectedTaxCondition.IsBlended">
        <table>
          <thead>
            <tr>
              <th class="label-cell">Operações Nacionais</th>
              <th class="label">&euro;</th>
              <th class="label">%</th>
              <th class="label">+I.F</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="label-cell">TSC Cartões Débito Consumer</td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.NatTSCDebitCard" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.NatTSCDebitCardFixed" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><f7-checkbox :checked="selectedTaxCondition.NatTSCDebitCardIF" /></td>
            </tr>
            <tr>
              <td class="label-cell">TSC Cartões Crédito Consumer</td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.NatTSCCreditCard" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.NatTSCCreditCardFixed" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><f7-checkbox :checked="selectedTaxCondition.NatTSCCreditCardIF" /></td>
            </tr>
            <tr>
              <td class="label-cell">TSC Cartões Débito Empresa</td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.NatTSCCompanyDebitCard" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.NatTSCCompanyDebitCardFixed" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><f7-checkbox :checked="selectedTaxCondition.NatTSCCompanyDebitCardIF" /></td>
            </tr>
            <tr>
              <td class="label-cell">TSC Cartões Crédito Empresa</td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.NatTSCCompanyCreditCard" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.NatTSCCompanyCreditCardFixed" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><f7-checkbox :checked="selectedTaxCondition.NatTSCCompanyCreditCardIF" /></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="data-table card" v-if="!selectedTaxCondition.IsBlended">
        <table>
          <thead>
            <tr>
              <th class="label-cell">Operações Intracomunitárias <br/>VISA e Mastercard</th>
              <th class="label">&euro;</th>
              <th class="label">%</th>
              <th class="label">+I.F</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="label-cell">TSC Cartões Débito Consumer</td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.VmTSCDebitCard" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.VmTSCDebitCardFixed" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><f7-checkbox :checked="selectedTaxCondition.VmTSCDebitCardIF" /></td>
            </tr>
            <tr>
              <td class="label-cell">TSC Cartões Crédito Consumer</td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.VmTSCCreditCard" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.VmTSCCreditCardFixed" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><f7-checkbox :checked="selectedTaxCondition.VmTSCCreditCardIF" /></td>
            </tr>
            <tr>
              <td class="label-cell">TSC Cartões Débito Empresa</td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.VmTSCCompanyDebitCard" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.VmTSCCompanyDebitCardFixed" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><f7-checkbox :checked="selectedTaxCondition.VmTSCCompanyDebitCardIF" /></td>
            </tr>
            <tr>
              <td class="label-cell">TSC Cartões Crédito Empresa</td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.VmTSCCompanyCreditCard" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.VmTSCCompanyCreditCardFixed" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><f7-checkbox :checked="selectedTaxCondition.VmTSCCompanyCreditCardIF" /></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="data-table card" v-if="!selectedTaxCondition.IsBlended">
        <table>
          <thead>
            <tr>
              <th class="label-cell">Operações NEEA</th>
              <th class="label">&euro;</th>
              <th class="label">%</th>
              <th class="label">+I.F</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="label-cell">Cartões Débito Consumer NEEA</td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.ConsumerNEEADebitCard" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.ConsumerNEEADebitCardFixed" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><f7-checkbox :checked="selectedTaxCondition.ConsumerNEEADebitCardIF" /></td>
            </tr>
            <tr>
              <td class="label-cell">Cartões Crédito Consumer NEEA</td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.ConsumerNEEACreditCard" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.ConsumerNEEACreditCardFixed" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><f7-checkbox :checked="selectedTaxCondition.ConsumerNEEACreditCardIF" /></td>
            </tr>
            <tr>
              <td class="label-cell">Cartões Débito Empresa NEEA</td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.CompanyNEEADebitCard" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.CompanyNEEADebitCardFixed" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><f7-checkbox :checked="selectedTaxCondition.CompanyNEEADebitCardIF" /></td>
            </tr>
            <tr>
              <td class="label-cell">Cartões Crédito Empresa NEEA</td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.CompanyNEEACreditCard" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><input class="taxfield" v-model="selectedTaxCondition.CompanyNEEACreditCardFixed" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate /></td>
              <td class="label"><f7-checkbox :checked="selectedTaxCondition.CompanyNEEACreditCardIF" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <f7-button 
            class="button-fill button-round u-sidemargin" 
            iconMaterial="check" 
            @click="onClose">&nbsp;Ok</f7-button>
    </f7-page>
  </f7-popup>
</template>

<script>
export default {
  name: 'taxcondition-form',
  data: function(){
    return {
      // StartDate: new Date().toLocaleString('pt-PT', { timeZone: 'UTC' }),
      // EndDate: new Date().toLocaleString('pt-PT', { timeZone: 'UTC' }),
    }
  },
  props:{ 
    selectedTaxCondition: Object,
    popupOpened: Boolean,
    onClose: Function,
  },
  computed: {
    StartDate: function(){
      return this.StartDateString();
    },
    EndDate: function(){
      return this.EndDateString();
    }
  },
  methods: {
    StartDateString: function(){
      if(this.selectedTaxCondition == null) return new Date().toLocaleString('pt-PT');
      if(this.selectedTaxCondition.ConditionStartDate == null) return new Date().toLocaleString('pt-PT');
      return new Date(this.selectedTaxCondition.ConditionStartDate).toLocaleDateString('pt-PT');
    },
    EndDateString: function(){
      if(this.selectedTaxCondition == null) return new Date().toLocaleString('pt-PT');
      if(this.selectedTaxCondition.ConditionEndDate == null) return new Date().toLocaleString('pt-PT');
      return new Date(this.selectedTaxCondition.ConditionEndDate).toLocaleDateString('pt-PT');
    },
    getTaxForm: function(){
      var self = this;
      return JSON.parse(JSON.stringify(self.selectedTaxCondition));
    },
    editStartDate: function(){
      this.showCalendar(true);
    },
    editEndDate: function(){
      this.showCalendar(false);
    },
    showCalendar: function(isStart){
      var self = this;
      var dateWhen = isStart ? self.selectedTaxCondition.ConditionStartDate : self.selectedTaxCondition.ConditionEndDate;
      if(dateWhen == null) dateWhen = new Date();
      var calendarModal = self.$f7.calendar.create({
        openIn: 'customModal',
        header: false,
        footer: false,
        dateFormat: 'dd M yyyy',
        closeOnSelect: true,
        backdrop: false,
        value: [ dateWhen ],
        dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto' , 'Setembro' , 'Outubro', 'Novembro', 'Dezembro'],
        monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dec'],
        on: {
          closed: function(){
            var selectedDates = calendarModal.getValue();
            if (selectedDates.length > 0 && new Date(dateWhen).getDate() != selectedDates[0].getDate())
            {
              dateWhen = selectedDates[0];
              if(isStart) {
                self.selectedTaxCondition.ConditionStartDate = dateWhen;
                self.StartDateString();
              }
              else{
                self.selectedTaxCondition.ConditionEndDate = dateWhen;
                self.EndDateString();
              } 
            }
          }
        }
      });

      calendarModal.open();
    },
  }
}
</script>

<style scoped>
    .u-nopadding{
        padding: 0em !important;
    }

    .u-zeromargin{
        margin: 0em 0;
    }

    .c-map{
        width:100%; 
        height:20em;
    }

    .u-textwrapper{
        width:100%;
        overflow-y: auto;
        white-space: pre-wrap;
    }

    .taxfield{
      width: 50%;
      background-color: lightgrey;
      text-align: right;
    }

    .data-table td, .data-table th{
      padding-left: 0;
      padding-right: 0;
    }
</style>