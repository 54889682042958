<template>
    <!-- App -->
    <f7-app :params="f7params">
        <f7-view url="/" :main="true" class="ios-edges"></f7-view>
    </f7-app>
</template>


<script>
// Import Routes...
import routes from './routes.js'
import BackendApi from './assets/vue/components/backend-api.js'

let theme = 'auto';
if (document.location.search.indexOf('theme=') >= 0) {
  theme = document.location.search.split('theme=')[1].split('&')[0];
}

export default {
    data() {
        return {
            f7params: {
                theme,
                routes,
                id: 'pt.pagaqui.c2s',
            }
        }
    },
    mounted: function(){
        var self = this;
        BackendApi.logOut();
        document.addEventListener("backbutton", self.backKeyOverride, false);
    },
    methods: {
        backKeyOverride: function(){
            if(this.$f7.views.main.router.url == '/'){
                window.navigator.app.exitApp();
            }
            else{
                this.$f7.views.main.router.back();
            }    
        },
        async logout () {
            await this.$auth.signOut()
        }
    }
}
</script>

