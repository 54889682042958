// Import Vue
import Vue from 'vue'
//import VueCordova from 'vue-cordova'
import Framework7 from 'framework7/framework7.esm.bundle.js';
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import F7 Style
import 'framework7/css/framework7.css'
import './static/framework7.bundle.min.css'
import './static/framework7-vue.bundle.min.js'
// Import F7 iOS Icons
import 'framework7-icons/css/framework7-icons.css'

// Import Material Icons
//import MaterialIcons from 'material-design-icons/iconfont/material-icons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

// Import Fontawesome Theme Styles
import 'font-awesome/css/font-awesome.css'

// import AppStyles from './assets/sass/main.scss'

import app from './main.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vuelidate from 'vuelidate'
import VueGoogleMap from 'vuejs-google-maps'

import Auth from '@okta/okta-vue'
import { OktaAuth } from '@okta/okta-auth-js'
/* eslint-disable */
const oktaSettings = appSettings;
const oktaAuth = new OktaAuth({
  issuer: oktaSettings.okta.ISSUER,
  clientId: oktaSettings.okta.CLIENT_ID,
  redirectUri: window.location.origin,
  scopes: ['openid', 'profile', 'email']
})
Vue.use(Auth, { oktaAuth })


Framework7.use(Framework7Vue)
//Vue.use(VueCordova)
Vue.use(VueAxios, axios)

Vue.use(Vuelidate)
Vue.use(VueGoogleMap, {
  load: {
    apiKey: 'AIzaSyB1uKoti6PHUG5-lQXKNkmGhQb08GPnjWk',
    libraries: ['places','visualization']
  }
})

Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
});

// Init Vue App
export default new Vue({
  // Root Element
  el: '#app',
  render: c => c('app'),
  components: {
    app
  }
});