<template>
    <f7-page>
        <c2snavbar :showBack="false" :title="versionDisplay" />
        <f7-list class="components-list" v-if="!this.loggedIn">
            <f7-list-item panel-close
                key="/login"
                link="/login" 
                title="Login">
                <f7-icon slot="media" material="person"></f7-icon>
            </f7-list-item>
        </f7-list>

        <f7-block-title v-if="this.loggedIn">Opções</f7-block-title>
        <f7-list class="components-list" v-if="this.loggedIn">
            <f7-list-item panel-close
                v-for="item in menu"
                :key="item.path"
                :link="item.path" 
                :title="item.menu.title" 
                :style="{ display: item.menu.show ? 'auto': 'none'}">
                <f7-icon slot="media" :material="item.menu.icon"></f7-icon>
            </f7-list-item>
        </f7-list>

        <f7-block-title>Tema</f7-block-title>
        <f7-list simple-list>
            <f7-list-item>
                <span>Escuro</span>
                <f7-toggle 
                    color="blue" 
                    :checked="toogleDark"
                    @change.native="setLayoutTheme" >
                </f7-toggle>
            </f7-list-item>
        </f7-list>
    </f7-page>
</template>

<script>
import { f7Page, f7BlockTitle } from 'framework7-vue';
//import routes from '../../../routes.js';
import BackendApi from '../components/backend-api.js'
import DialogsMixin from '../mixins/dialogs.js'
import c2snavbar from '../components/c2s-navbar.vue'

export default {
    mixins: [ DialogsMixin ],
    components: {
        c2snavbar,
        f7Page, 
        f7BlockTitle
    },
    data: function(){
        return {
            toogleDark: this.$f7.root.hasClass('theme-dark'),
            // eslint-disable-next-line
            version: appSettings.VERSION,
            latitude : this.latitude,
            longitude : this.longitude,
            app: this.$f7,
            api: BackendApi,
            loggedIn: false
        }
    },
    computed: {
        menu: function(){
            return this.$f7.routes;
        },
        versionDisplay: function(){
            if(this.mode != '' || this.mode != 'prod') return `${this.version} (${_env.toUpperCase()})`;
            return this.version;
        }
    },
    created: function(){
        var self = this;
        if(!navigator.onLine){
                self.showDialog('Sem internet!', false);
                return;
        }

        if (navigator.geolocation)
        {
            navigator.geolocation.getCurrentPosition(
                position => { 
                    self.latitude = position.coords.latitude;
                    self.longitude = position.coords.longitude;
                    self.message = '';
                    self.loaded = true;
                },
                reason => {
                    self.message = reason.message;
                    self.loaded = true;
                },
                { 
                    enableHighAccuracy: true, 
                    timeout: 10000 
            });
        }
        else
        {
            self.showDialog("Localização não iniciada!", false);
        }
    },
    mounted: function (){
        var self = this;
        self.loggedIn = BackendApi.loggedIn();
        // if(window.location.href.startsWith("http://localhost")){
        //     localStorage.setItem("accessToken", JSON.stringify({debug: true}));
        //     localStorage.setItem("idToken", JSON.stringify({claims: {email: "marco.parreira@saltpay.co", exp: 99999999999}}));
        //     self.loggedIn = true;
        // }
        document.body.focus();
    },
    methods: {
        setLayoutTheme: function() {
            const app = this.$f7;
            app.root.removeClass('theme-dark theme-light');
            this.toogleDark = !this.toogleDark;
            if (this.toogleDark)
            {
                app.root.addClass(`theme-dark`);
            }
            else
            {
                app.root.addClass(`theme-light`);   
            }
        },
        logOut(){
            BackendApi.logOut();
            this.loggedIn = false;
        }
    }
};
</script>

<style>
    html {
        --brand-color-0: #f7fbe7;
        --brand-color-1: #c6db84;
        --brand-color-2: #bad36c;
        --brand-color-3: #aecb53;
        --brand-color-4: #a2c43b;
    }

    .navbar-logo {
        color: var(--brand-color-0) !important;
    }

    .c-version{
        color: #bfbfbf;
    }

    .u-signout{
        background: transparent !important;
    }
    .u-signout a{
        color: black !important;
        text-decoration: underline;
        margin: auto;
    }
</style>