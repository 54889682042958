export default
{
  DeviceTerminalId: '',
  CRMAgentId: 0,
  AgentTypeId: 0,
  AgentCode: '',
  Tin: 0,
  Name: '',
  ContactName: '',
  Email: '',
  Phone1: '',
  Address: '',
  PostalCodePrefix: 0,
  PostalCodeSuffix: 0,
  PostalCodeCity: '',
  Active: true,
  CreatedDate: null
}