<template>
  <f7-page>
    <!-- TOP BAR -->
    <c2snavbar :showBack="true" title="Localizar P.V." />
    <!-- GPS -->
    <f7-card v-if="!hasLoaded" content="A obter localização do dispositivo..."></f7-card> 
    <f7-card v-if="!isValid && hasLoaded">
      <f7-card-content><p>Não foi possivel obter a localização geográfica do seu dispositivo. {{position.message}}</p></f7-card-content>
    </f7-card>

    <form class="list form-store-data" id="gps-form" v-if="isValid && hasLoaded">
      <!-- AGENT -->
      <ul>
        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">Agente</div>
            <div class="item-input-wrap">
              <input name="agentcode" pattern="\d*" type="number" placeholder="Código do Agente" v-model="agentCode" >
              <span class="input-clear-button"></span>
            </div>
            <div class="item-inner">
              <f7-button class="button-round" small iconMaterial="search" v-if="agentCode" @click="getSalePointList">&nbsp;Pesquisar</f7-button>
            </div>
            <div class="item-inner" v-if="salePoints">
              <div class="item-title item-label">Ponto de venda</div>
              <div class="item-input-wrap input-dropdown-wrap">
                <select v-model="selectedSalePoint">
                  <option :value="null" disabled>Escolha o ponto de venda</option>
                  <option v-for="item in salePoints" :key="item.SalePointId" :value="item">
                    {{ item.Name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <!-- LOCATION -->
      <ul v-if="selectedSalePoint">
        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">Distrito</div>
            <div class="item-input-wrap input-dropdown-wrap">
              <select placeholder="Escolha..." v-model="selectedDistrict" @change="dicofreChanged()">
                <option :value="null" disabled>Escolha o distrito</option>
                <option v-for="item in districtData" :key="item.code" :value="item">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </li>
        <li class="item-content item-input" v-if="selectedDistrict">
          <div class="item-inner">
            <div class="item-title item-label">Concelho</div>
            <div class="item-input-wrap input-dropdown-wrap">
              <select v-model="selectedCounty" @change="dicofreChanged()">
                <option :value="null" disabled>Escolha o concelho</option>
                <option v-for="item in selectedDistrict.data" :key="item.code" :value="item">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </li>
        <li class="item-content item-input" v-if="selectedCounty">
          <div class="item-inner">
            <div class="item-title item-label">Freguesia</div>
            <div class="item-input-wrap input-dropdown-wrap">
              <select v-model="selectedParish" @change="dicofreChanged()">
                <option :value="null" disabled>Escolha a freguesia</option>
                <option v-for="item in selectedCounty.data" :key="item.code" :value="item" >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </li>
      </ul>
      <!-- SUBMIT -->
      <ul>
        <li class="item-content item-input" v-if="selectedCounty">
          <div class="item-inner">
            <f7-button class="button-fill button-round" iconMaterial="where_to_vote" v-if="canSubmit" @click="submitGPS">&nbsp;Enviar</f7-button>
          </div>
        </li>
      </ul>
    </form>
  </f7-page>
</template>


<script>
import c2snavbar from '../components/c2s-navbar.vue'
import GpsRequestParams from '../models/gps-request-params.js'
import Districts from '../data/districts.js'
import BackendApi from '../components/backend-api.js'
import DialogsMixin from '../mixins/dialogs.js'

export default {
  mixins: [ DialogsMixin ],
  components: {
        c2snavbar
    },
  data: function(){
    return {
      loaded: false,
      latitude: this.latitude,
      longitude: this.longitude,
      message: this.message,
      selectedDistrict: null,
      selectedCounty: null,
      selectedParish: null,
      agentCode: null,
      salePoints: null,
      selectedSalePoint: null,
      submitResult: null
    }
  },
  created: function(){
    var self = this;

    if (navigator.geolocation)
    {
      navigator.geolocation.getCurrentPosition(
        position => { 
          self.latitude = position.coords.latitude;
          self.longitude = position.coords.longitude;
          self.message = '';
          self.loaded = true;
        },
        reason => {
          self.message = reason.message;
          self.loaded = true;
        },
        { 
          enableHighAccuracy: true, 
          timeout: 30000 
        });
    }
    else
    {
      self.showDialog("Localização não iniciada!", false);
    }
  },
  computed:{
    location: function(){
      var self = this;
      return {
        selectedDistrict: self.selectedDistrict,
        selectedCounty: self.selectedCounty,
        selectedParish: self.selectedParish
      };
    },
    position: function(){
      var self = this;
      return { 
        latitude: self.latitude,
        longitude: self.longitude, 
        message: self.message 
      };
    },
    isValid: function(){
      var self = this;
      return !(self.message && self.message === '') && (self.latitude && self.longitude);
    },
    hasLoaded: function(){
      var self = this;
      return self.loaded;
    },
    canSubmit: function(){
      var self = this;
      return (self.isValid 
        && self.hasLoaded 
        && self.agentCode != 0 
        && self.selectedSalePoint 
        && self.selectedDistrict 
        &&  self.selectedCounty 
        && self.selectedParish);
    },
    districtData: function(){
      return Districts;
    }
  },
  methods:{
    getSalePointList: function()
    {
      var self = this;
      self.showDialog('A pesquisar...', true);

      BackendApi.getSalePointList(
        {"AgentCode": self.agentCode,"DeviceTerminalId": BackendApi.deviceId() },
        result => {
          self.salePoints = result.data.SalePointList;
          self.selectedSalePoint = null;
          
          if (!self.salePoints || self.salePoints.length == 0)
          {
            self.showDialog('Agente não encontrado!', false);
            return;
          }

          if (self.salePoints.length == 1)
          {
            self.selectedSalePoint = self.salePoints[0];
          }

          self.hideDialog();
        },
        error => {
          self.salePoints = null;
          self.selectedSalePoint = null;
          self.showDialog('Agente não encontrado!', false);
        }
      )
    },
    dicofreChanged: function(){
      var self = this;
      if(self.selectedDistrict && !self.selectedCounty && self.selectedDistrict.data.length == 1)
      {
        self.selectedCounty = self.selectedDistrict.data[0];
      }

      if(self.selectedCounty && !self.selectedParish && self.selectedCounty.data.length == 1)
      {
        self.selectedParish = self.selectedCounty.data[0];
      }
    },
    submitGPS: function(){
      var self = this;

      if (!self.canSubmit)
      {
        self.showDialog("Dados incorrectos!", false);
        return;
      }

      GpsRequestParams.Latitude = self.latitude;
      GpsRequestParams.Longitude = self.longitude;
      GpsRequestParams.DeviceTerminalId = BackendApi.deviceId(); 
      GpsRequestParams.AgentCode = self.agentCode;
      GpsRequestParams.SalePointId = self.selectedSalePoint.SalePointId;
      GpsRequestParams.DiCoFre = self.selectedDistrict.code+self.selectedCounty.code+self.selectedParish.code;

      self.showDialog("A enviar dados...", true);
      
      BackendApi.sendGpsCoordinates(GpsRequestParams,
        result => {
          if (result.data && result.data.SalePointId === 0)
          {
            self.showDialog("Os dados não foram aceites.", false);
          }
          else
          {
            self.hasSubmited = true;
            self.showDialog("Localização do ponto de venda enviada!", false);
          }
        },
        error => {
          self.showDialog("Erro ao enviar dados! Por favor, tente novamente ou contacte o suporte.", false);
        }
      );
    }
  }
};
</script>
