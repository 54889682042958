<template>
    <f7-page>
        <c2snavbar :showBack="true" :title="version" />
        <form class="list form-store-data">
            <f7-card class="cardlist">
                <f7-card-content>
                    <ul>
                        <li class="item-content item-input login">
                            <div class="item-inner">
                                <div class="item-title item-label">Username</div>
                                <div class="item-input-wrap">
                                    <input type="text" id="username" placeholder="username" :maxlength="30" :minlength="6" :required="true" v-model="user"/>
                                    <div class="item-input-info"></div>
                                </div>
                            </div>
                        </li>
                        <li class="item-content item-input">
                            <div class="item-inner">
                                <div class="item-title item-label">Password</div>
                                <div class="item-input-wrap">
                                    <input type="password" id="password" placeholder="password" :maxlength="20" :minlength="6" :required="true" v-model="pwd"/>
                                    <div class="item-input-info"></div>
                                </div>
                            </div>
                        </li>
                        <li class="item-content item-input">
                            <div class="item-inner">
                                <f7-button 
                                    class="button-fill u-center" 
                                    iconMaterial="person" 
                                    @click="login()">&nbsp;Login</f7-button>
                            </div>
                        </li>
                    </ul>
                </f7-card-content>
            </f7-card>
        </form>
    </f7-page>
</template>

<script>
import { f7Page } from 'framework7-vue';
import c2snavbar from '../components/c2s-navbar.vue'
import BackendApi from '../components/backend-api.js'
import DialogsMixin from '../mixins/dialogs.js'

export default {
    mixins: [ DialogsMixin ],
    components: {
        c2snavbar,
        f7Page
    },
    name: 'Login',
    data: function(){
        return {
            app: this.$f7,
            api: BackendApi,
            loggedIn: false,
            version: appSettings.VERSION,
            user: '',
            pwd: ''
        }
    },
    methods: {
        login() {
            var self = this;
            if(this.user != "" && this.pwd != ""){
                this.api.deviceLogin({
                        DeviceTerminalId: '',
                        DeviceAppVersion: this.api.deviceAppVersion(),
                        UserName: this.user,
                        Password: this.pwd
                    },
                    result => {
                        if (result.data && result.data.Valid == true)
                        {
                               localStorage.setItem("accessToken", JSON.stringify({debug: false}));
                               localStorage.setItem("idToken", JSON.stringify({claims: {email: result.data.DeviceTerminalId, exp: 99999999999}}));
                               this.$f7router.navigate('/');
                        }
                        else
                        {
                            self.showDialog("Utilizador inválido!", false);
                        }
                    },
                    error => {
                        self.showDialog("Erro ao enviar dados! Por favor, tente novamente ou contacte o suporte.", false);
                    } 
                )
            }
        }
    }
}

</script>

<style scoped>
.cardlist ul::before{
    background-color: transparent !important;
}

.cardlist ul::after{
    background-color: transparent !important;
}
.u-center{
    align-self: center;
}
</style>