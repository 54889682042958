<template>
  <f7-page>
    <c2snavbar :showBack="true" title="Agenda" />
    <f7-subnavbar>
        <f7-link iconMaterial="arrow_left" text="" @click="moveDays(-1)"></f7-link>
        <f7-link :text="currentDateString" @click="showCalendar"></f7-link>
        <f7-link iconMaterial="arrow_right" text="" @click="moveDays(1)"></f7-link>
    </f7-subnavbar>
    <div class="data-table card">
      <table>
        <thead>
          <tr>
            <th class="label-cell">Titulo</th>
            <th class="label">Inicio</th>
            <th class="label">Duração</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in schedule" :key="index" @click="showEventDetail(item)">
            <td class="label-cell">{{item.Title}}</td>
            <td class="label">{{item.StartTime}}</td>
            <td class="label">{{item.Duration}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <calendar-detail :popupOpened="popupOpened" :onClose="closePopup" :selectedEvent="selectedEvent" />
  </f7-page>
</template>

<script>
import c2snavbar from '../components/c2s-navbar.vue'
import BackendApi from '../components/backend-api.js'
import DialogsMixin from '../mixins/dialogs.js'
import CalendarDetail from '../components/calendar-detail.vue'

export default {
  components: {
    c2snavbar,
    'calendar-detail': CalendarDetail
  },
  mixins: [ DialogsMixin ],
  data: function(){
    return {
      popupOpened: false,
      dateWhen: new Date(),
      schedule: [],
      selectedEvent: {},
      mapOptions: {
        zoom: 15,
        minZoom: 7,
        mapTypeId: 'terrain',
        heading: 0
      }
    };
  },
  created: function(){
    this.getSchedule();
  },
  computed: {
    currentDateString: function(){
      return this.dateWhen.toLocaleDateString('pt-PT', { timeZone: 'UTC' });
    }
  },
  methods: {
    moveDays: function(days){
      var newDate = new Date(this.dateWhen);
      this.dateWhen = new Date(newDate.setDate(newDate.getDate() + days));
      this.getSchedule();
    },
    getSchedule: function(){
      var self = this;
      
      self.showDialog("",true);

      BackendApi.getSchedule(
        {"When": self.dateWhen,"DeviceTerminalId": BackendApi.deviceId()},
        result => {
          self.hideDialog();
          self.schedule = result.data.Events;
        },
        error => {
          //self.hasSubmited = true; // force dialog close to navigate home
          self.showDialog('Não foi possivel contactar a Pagaqui! '+error, false);
        }
      )
    },
    showCalendar: function(){
      var self = this;
      var calendarModal = self.$f7.calendar.create({
        openIn: 'customModal',
        header: false,
        footer: false,
        dateFormat: 'dd M yyyy',
        closeOnSelect: true,
        backdrop: false,
        value: [ self.dateWhen ],
        dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto' , 'Setembro' , 'Outubro', 'Novembro', 'Dezembro'],
        monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dec'],
        on: {
          closed: function(){
            var selectedDates = calendarModal.getValue();
            if (selectedDates.length > 0 && self.dateWhen.getDate() != selectedDates[0].getDate())
            {
              self.dateWhen = selectedDates[0];
              self.getSchedule();
            }
          }
        }
      });
      calendarModal.open();
    },
    showEventDetail: function(item){
      var self = this;
      self.showDialog(null,true);
      self.selectedEvent = { Position: null};
      BackendApi.getCalendarEventDetail(
        {
          CalendarEventDetailId: item.Id, 
          DeviceTerminalId: BackendApi.deviceId(), 
          DeviceAppVersion: BackendApi.deviceAppVersion()
        },
        result => {
          if(result.data.Result == null)
          {
            self.showDialog("Este agendamento não tem detalhes!", false);
            return;
          }

          self.selectedEvent = result.data.Result;
          self.selectedEvent.Title = item.Title;
          if(self.selectedEvent.SalePointLatitude != null)
          {
            self.selectedEvent.Position = {
              lat: Number(self.selectedEvent.SalePointLatitude.replace(',','.')),
              lng: Number(self.selectedEvent.SalePointLongitude.replace(',','.'))
            };
          }
          else{
            self.selectedEvent.Position = null;
          }
          self.hideDialog();
          self.popupOpened = true;
        },
        error => {
          self.showDialog('Não foi possivel contactar a Pagaqui! '+error, false);
        }
      );
    },
    closePopup: function(){
      this.popupOpened = false;
    },    
  }
};
</script>

<style scoped>
.brand
{
  background-color: var(--brand-color-0) !important;
}
</style>
