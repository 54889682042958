<template>
  <f7-page>
    <f7-navbar title="Contatos" back-link="Back" sliding></f7-navbar>
    <!-- ENTITY PANEL  -->
    <f7-card>
      <f7-card-content>
        <p><b>Nome&nbsp;</b> {{name}}</p>
        <p v-if="agent"><b>Agente&nbsp;</b> {{agentCode}}</p>
        <p v-if="!agent"><b>NIF&nbsp;</b> {{nif}}</p>
      </f7-card-content>
    </f7-card>
    <div class="data-table">
      <table>
        <thead>
          <tr>
            <th class="label-cell" style="text-overflow: ellipsis; overflow: hidden; width: 100px; white-space: nowrap;max-width: 150px;padding-left: 5px;padding-right: 10px;">Assunto</th>
            <th class="label-cell" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;padding-right: 10px;padding-left: 0px;">Estado</th>
            <th class="label-cell" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;padding-right: 10px;padding-left: 0px;">Criado em</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in contactList" :key="item.ContactId" v-on:click="showComments(item)">
            <td class="label-cell" style="text-overflow: ellipsis; overflow: hidden; width: 100px; white-space: nowrap;max-width: 150px;padding-left: 5px;padding-right: 10px;">{{item.Description}}</td>
            <td class="label-cell" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;padding-right: 10px;padding-left: 0px;">{{item.Status}}</td>
            <td class="label-cell" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;padding-right: 10px;padding-left: 0px;">{{item.CreatedDate}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <f7-popup class="demo-popup" :opened="popupOpened" @popup:closed="popupOpened = false">
      <f7-page>
        <f7-navbar>
          <f7-block-title>Contactos</f7-block-title>
          <f7-nav-right>
            <f7-link popup-close>Fechar</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-card>
          <f7-card-content>
            <p><b>Assunto:&nbsp;</b> {{selectedContact.Description}}</p>
            <p><b>Estado:&nbsp;</b> {{selectedContact.Status}}</p>
            <p v-if="showResolveStatus">
              <f7-button class="button-round button-fill" title="Marcar como Resolvido" @click="resolveContactStatus" style="width:100%">Marcar como Resolvido</f7-button>
            </p>
          </f7-card-content>
        </f7-card>

        <f7-card>
          <f7-card-content>
            <div style="width:100%;overflow-y: auto;white-space: pre-wrap;">{{selectedContact.Comments}}</div>
            
          </f7-card-content>
        </f7-card>
         <f7-block>
              <p><f7-button class="button-fill button-round" @click="popoverOpened = true">&nbsp;Adicionar Comentário</f7-button></p>
            </f7-block>
        <f7-card>
          <f7-card-content>
            <p><b>Criado&nbsp;por:</b>&nbsp;&nbsp;&nbsp;&nbsp;<u>{{selectedContact.CreatedBy}}</u>&nbsp;<b>em</b>&nbsp;{{selectedContact.CreatedDate}}</p>
            <p v-if="selectedContact.UpdatedBy != null"><b>Alterado&nbsp;por:</b>&nbsp;&nbsp;<u>{{selectedContact.UpdatedBy}}</u>&nbsp;<b>em</b>&nbsp;{{selectedContact.UpdatedDate}}</p>
          </f7-card-content>
        </f7-card>
      </f7-page>
    </f7-popup>

    <f7-popup class="comment-popup" :opened="popoverOpened" @popup:closed="popoverOpened = false">
      <f7-page>
        <f7-navbar>
          <f7-block-title>Adicionar Comentário</f7-block-title>
          <f7-nav-right>
            <f7-link popup-close>Fechar</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-card class="list">
          <ul>
            <li class="item-content item-input">
              <div class="item-inner">
                  <div class="item-title item-label">Novo Comentário</div>
                  <div class="item-input-wrap">
                      <textarea placeholder="Comentário" maxlength="2048" v-model="newComment" rows="10"></textarea>
                  </div>
              </div>
            </li>
          </ul>
        </f7-card>
        <div class="item-inner">
            <f7-button class="button-round" iconMaterial="cloud_upload" @click="insertComment()">&nbsp;Adicionar Comentário</f7-button>
        </div>
      </f7-page>
    </f7-popup>
  </f7-page>

  
</template>

<script>

import BackendApi from '../components/backend-api.js'
import DialogsMixin from '../mixins/dialogs.js'
import CrmComment from '../models/crm-contact.js'

export default {
  mixins: [ DialogsMixin ],
  components: {
    
  },
  data: function(){
    return {
      popupOpened: false,
      popoverOpened: false,
      agent: (this.$f7route.query.agent == "true"),
      id: parseInt(this.$f7route.query.id),
      name: this.$f7route.query.name,
      nif: this.$f7route.query.nif,
      agentCode: this.$f7route.query.agentCode,
      contacts: [],
      selectedContact: {},
      newComment: null
    }
  },
  created: function() {
      var self = this;
      self.showDialog("",true);
      self.getContacts();
  },
  computed: {
    contactList: function(){
      return this.contacts;
    },
    showResolveStatus: function(){
      return this.selectedContact.Status != "Resolvido";
    }
  },
  methods: {
    getContacts: function(){
      var self = this;
      BackendApi.getContactList(
        {
          AgentId: self.id, 
          IsAgent: self.agent, 
          DeviceTerminalId: BackendApi.deviceId(), 
          DeviceAppVersion: BackendApi.deviceAppVersion()
        },
        result => {
          if(result.data.Contacts.length == 0)
          {
            self.showDialog("Não foram encontrados contactos!", false);
            return;
          }
          result.data.Contacts.forEach(self.setContacts);
          self.hideDialog();
        },
        error => {
          self.showDialog('Não foi possivel contactar a Pagaqui! '+error, false);
        }
      )
    },
    resolveContactStatus: function(){
        var self = this;
        var request = {
            DeviceTerminalId: BackendApi.deviceId(),
            ContactId: self.selectedContact.ContactId,
            IsAgent: self.agent
        }

        self.showDialog(null, true);
        BackendApi.resolveContactStatus(request,
            result => {
                if(result.data.Result != true)
                {
                    self.showDialog("Não foi possivel alterar o estado!");
                    return;
                }
                
                self.selectedContact.Status = "Resolvido";
                self.showDialog("Contacto resolvido com sucesso!");
            },
            error => {
                self.showDialog('Não foi possivel contactar a Pagaqui! '+error);
            }
        )
    },
    setContacts: function(value) {
      this.contacts.push(value); 
    },
    showComments: function(value){
      if(value.Comments.length == 0){
        this.showDialog('Não foram adicionados comentários', false);
        return;
      }

      var text = "";
      for (var i = 0; i < value.Comments.length; i++) {
        text += `[${value.Comments[i].CreatedDate}] ${value.Comments[i].Text}`;
        text += "\r\n";
      }
      this.selectedContact = value;//{Description: value.Description, Comment: text};
      this.popupOpened = true;
    },
    insertComment: function(){
        var self = this;
        var request = {
            DeviceTerminalId: BackendApi.deviceId(),
            ContactId: self.selectedContact.ContactId,
            IsAgent: self.agent,
            Comment: self.newComment,
        }

        self.showDialog(null, true);
        BackendApi.insertComment(request,
            result => {
                if(result.data.Result != true)
                {
                    self.showDialog("Não foi possivel inserir o comentário!");
                    return;
                }

                // added
                self.selectedContact.Comments = self.selectedContact.Comments + "\r***" + new Date().toLocaleString() +"-" + result.data.DeviceTerminalId + ">\n" + self.newComment;
                self.newComment = null;
                self.showDialog("Comentário inserido!");
                self.popoverOpened = false;
            },
            error => {
                self.showDialog('Não foi possivel contactar a Pagaqui! '+error);
                self.popoverOpened = false;
            }
        )
    },
  }
};
</script>
