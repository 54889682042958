<template>
  <f7-page>
    <f7-navbar title="Criar Encomenda" back-link="Back" sliding></f7-navbar>
<!-- ENTITY PANEL  -->
    <f7-card>
      <f7-card-content>
        <p><b>Nome&nbsp;</b> {{agent.agentName}}</p>
        <p><b>Agente&nbsp;</b> {{agent.agentCode}}</p>
        <p><b>Ponto de venda&nbsp;</b> {{agent.salePointName}}</p>
      </f7-card-content>
    </f7-card>
<!-- ORDER FORM  -->
    <form ref="crmOrderForm" class="list form-store-data">
        <f7-card class="cardlist">
            <f7-card-content>
                <ul>
                    <li class="item-content item-input">
                        <div class="item-inner">
                            <div class="item-title item-label">Produto Encomenda</div>
                            <div class="item-input-wrap input-dropdown-wrap">
                                <select v-model="selectedProduct" required validate data-error-message="* Campo obrigatório" @change="productChanged()">
                                    <option :value="null" disabled>Escolha uma opção</option>
                                    <option v-for="item in dependencies.products" :key="item.Id" :value="item">{{item.Name}}</option>
                                </select>
                                <div class="item-input-info"></div>
                            </div>
                        </div>
                    </li>
                    <li class="item-content item-input">
                        <div class="item-inner">
                            <div class="item-title item-label">Método de Entrega</div>
                            <div class="item-input-wrap input-dropdown-wrap">
                                <select v-model="selectedDeliveryMethod" required validate data-error-message="* Campo obrigatório" @change="deliveryChanged()">
                                    <option :value="null" disabled>Escolha uma opção</option>
                                    <option v-for="item in dependencies.deliveryMethods" :key="item.Id" :value="item">{{item.Name}}</option>
                                </select>
                                <div class="item-input-info"></div>
                            </div>
                        </div>
                    </li>
                    <li class="item-content item-input">
                        <div class="item-inner">
                            <div class="item-title item-label">Tipo de Venda</div>
                            <div class="item-input-wrap input-dropdown-wrap">
                                <select v-model="selectedSaleType" required validate data-error-message="* Campo obrigatório" @change="updateModel()">
                                    <option :value="null" disabled>Escolha uma opção</option>
                                    <option v-for="item in dependencies.saleTypes" :key="item.Id" :value="item">{{item.Name}}</option>
                                </select>
                                <div class="item-input-info"></div>
                            </div>
                        </div>
                    </li>
                    <li class="item-content item-input item-input-with-info" v-if="showFreeMonths">
                        <div class="item-inner">
                            <div class="item-title item-label">Meses de oferta</div>
                            <div class="item-input-wrap">
                                <input 
                                    type="tel" 
                                    name="freeMonths" 
                                    pattern="\d*[\.,]{0,1}\d*" 
                                    maxlength="2" minlength="0" 
                                    validate 
                                    v-model="workingRecord.FreeMonths"
                                    @change="updateModel()"
                                />
                                <div class="item-input-info"></div>
                            </div>
                        </div>
                    </li>
                </ul>
            </f7-card-content>
        </f7-card>
<!-- BANK INFO -->
        <f7-card v-if="showBankInfo" class="cardlist">
            <f7-card-content>
                <ul>
                    <li class="item-content item-input">
                        <div class="item-inner">
                            <div class="item-title item-label">MCC</div>
                            <div class="item-input-wrap input-dropdown-wrap" style="float: left;">
                                <input class="taxfield" v-model="mcc" type="tel" pattern="\d*[\.,]{0,1}\d*" maxlength="20" minlength="1" required validate />
                                <div class="item-input-info"></div>
                            </div>
                        </div>
                    </li>
                    <li class="item-content item-input">
                        <div class="item-inner">
                            <div class="item-title item-label">Condições Económicas</div>
                            <div class="item-input-wrap input-dropdown-wrap" style="float: left;">
                                <select v-model="selectedTaxCondition" required validate data-error-message="* Campo obrigatório" @change="updateTaxForm()">
                                    <!-- <option :value="null" disabled>Escolha uma opção</option> -->
                                    <option v-for="item in selectedProduct.TaxConditions" :key="item.TaxConditionId" :value="item">{{item.Title}}</option>
                                </select>
                                <div class="item-input-info"></div>
                            </div>
                        </div>
                        <f7-button class="button-round" style="float:right" small iconMaterial="info" v-if="selectedTaxCondition" @click="popupOpened = true"></f7-button>
                    </li>
                    <li class="item-content item-input">
                        <f7-button class="button-round button-fill" style="float:right" iconMaterial="create" v-if="selectedTaxCondition" @click="popupOpenedTaxForm = true">&nbsp;Editar Condições</f7-button>
                    </li>
                    <li class="item-content item-input">
                        <div class="item-inner">
                            <div class="item-title item-label">Pais</div>
                            <div class="item-input-wrap input-dropdown-wrap">
                                <select v-model="selectedCountryBankCode" required validate data-error-message="* Campo obrigatório">
                                    <option :value="null" disabled>Seleccione o país</option>
                                    <option v-for="item in dependencies.countryBankCodes" :key="item.CountryId" :value="item">{{item.CountryName}}</option>
                                </select>
                                <div class="item-input-info"></div>
                            </div>
                        </div>
                    </li>
                    <li class="item-content item-input">
                        <div class="item-inner">
                            <div class="item-title item-label">Banco</div>
                            <div class="item-input-wrap input-dropdown-wrap">
                                <select v-model="selectedBankCode" required validate data-error-message="* Campo obrigatório">
                                    <option :value="null" disabled>Seleccione o banco</option>
                                    <option v-for="item in selectedCountryBankCode.BankCodes" :key="item.BankCodesId" :value="item">{{item.BankName}}</option>
                                </select>
                                <div class="item-input-info"></div>
                            </div>
                        </div>
                    </li>
                    <li class="item-content item-input">
                        <div class="item-inner">
                            <div class="item-title item-label">Swift</div>
                            <div class="item-input-wrap input-dropdown-wrap">
                                <select v-model="selectedBankCode" required validate data-error-message="* Campo obrigatório">
                                    <option :value="null" disabled>Seleccione o Swift</option>
                                    <option v-for="item in selectedCountryBankCode.BankCodes" :key="item.BankCodesId" :value="item">{{item.BicSwiftCode}}</option>
                                </select>
                                <div class="item-input-info"></div>
                            </div>
                        </div>
                    </li>
                    <li class="item-content item-input">
                        <div class="item-inner">
                            <div class="item-title item-label">IBAN</div>
                            <div class="item-input-wrap">
                                <input 
                                    type="tel" 
                                    name="iban" 
                                    maxlength="30" minlength="25" 
                                    v-model="workingRecord.Iban"
                                    required 
                                    validate 
                                    data-error-message="* Campo obrigatório"
                                    v-bind:class="{disabled: disableIBAN }"
                                    :disabled="disableIBAN"
                                    @blur="validateIban"
                                />
                                <div class="item-input-info"></div>
                            </div>
                        </div>
                    </li>
                </ul>
            </f7-card-content>
        </f7-card>
<!-- BANK INFO END -->
        <f7-card v-if="canUpdate" class="cardlist">
            <f7-card-content>
                <ul>
                    
                    <li class="item-content item-input item-input-with-info">
                        <div class="item-inner">
                            <div class="item-title item-label">Quantidade</div>
                            <div class="item-input-wrap">
                                <input 
                                    type="tel" 
                                    name="selectedQuantity" 
                                    pattern="\d*[\.,]{0,1}\d*" 
                                    maxlength="2" minlength="1" 
                                    required 
                                    validate 
                                    v-model="workingRecord.Quantity"
                                    @change="updateModel()"
                                    :disabled="selectedProduct.AllowQuantityChange != true"
                                    data-error-message="* Campo obrigatório"
                                    v-bind:class="{disabled: !selectedProduct.AllowQuantityChange}"
                                />
                                <div class="item-input-info"></div>
                            </div>
                        </div>
                    </li>
                    <li class="item-content item-input item-input-with-info">
                        <div class="item-inner">
                            <div class="item-title item-label">Valor Unitário</div>
                            <div class="item-input-wrap">
                                <input 
                                    type="tel" 
                                    name="unitValue" 
                                    pattern="\d*[\.,]{0,1}\d*" 
                                    maxlength="20" minlength="1" 
                                    required 
                                    validate 
                                    v-model="workingRecord.UnitValue"
                                    @change="updateModel()"
                                    :disabled="selectedProduct.AllowOrderPriceChange != true"
                                    data-error-message="* Campo obrigatório"
                                    v-bind:class="{disabled: !selectedProduct.AllowOrderPriceChange}"
                                />
                                <div class="item-input-info"></div>
                            </div>
                        </div>
                    </li>
                    <li class="item-content item-input">
                        <div class="item-inner">
                            <div class="item-title item-label">Portes de Envio</div>
                            <div class="item-input-wrap">
                                <input 
                                    type="tel" 
                                    name="deliveryPrice" 
                                    pattern="\d*[\.,]{0,1}\d*" 
                                    maxlength="2" minlength="1" 
                                    v-model="workingRecord.DeliveryValue"
                                    disabled
                                    class="disabled"
                                />
                            </div>
                        </div>
                    </li>
                    <li class="item-content item-input">
                        <div class="item-inner">
                            <div class="item-title item-label">Valor sem Iva</div>
                            <div class="item-input-wrap">
                                <input 
                                    type="tel" 
                                    name="amountWithoutVat" 
                                    pattern="\d*[\.,]{0,1}\d*" 
                                    maxlength="2" minlength="1" 

                                    v-model="workingRecord.AmountWithoutVat"
                                    disabled
                                    class="disabled"
                                />
                            </div>
                        </div>
                    </li>
                    <li class="item-content item-input">
                        <div class="item-inner">
                            <div class="item-title item-label">Taxa Iva</div>
                            <div class="item-input-wrap">
                                <input 
                                    type="tel" 
                                    name="amountWithoutVat" 
                                    pattern="\d*[\.,]{0,1}\d*" 
                                    maxlength="2" minlength="1" 
                                    v-model="selectedProduct.VatValue"
                                    disabled
                                    class="disabled"
                                />
                            </div>
                        </div>
                    </li>
                    <li class="item-content item-input">
                        <div class="item-inner">
                            <div class="item-title item-label">Valor Total com Iva</div>
                            <div class="item-input-wrap">
                                <input 
                                    type="tel" 
                                    name="amountWithVat" 
                                    pattern="\d*[\.,]{0,1}\d*" 
                                    maxlength="2" minlength="1" 
                                    v-model="workingRecord.AmountWithVat"
                                    disabled
                                    class="disabled"
                                />
                            </div>
                        </div>
                    </li>
                    <li class="item-content item-input">
                        <div class="item-inner">
                            <div class="item-title item-label">Observações</div>
                            <div class="item-input-wrap">
                                <textarea placeholder="Observações" maxlength="1000" v-model="workingRecord.Observations" rows="20"></textarea>
                            </div>
                        </div>
                    </li>
                </ul>
            </f7-card-content>
        </f7-card>
            <!-- SUBMIT -->
        <f7-button 
            class="button-fill button-round u-sidemargin" 
            iconMaterial="cloud_upload" 
            @click="createOrder()">&nbsp;Criar Encomenda</f7-button>
    </form>
    <taxcondition :popupOpened="popupOpened" :onClose="closePopup" :selectedTaxCondition="selectedTaxCondition" />
    <taxcondition-form ref="taxform" :popupOpened="popupOpenedTaxForm" :onClose="closePopupTaxForm" :selectedTaxCondition="selectedTaxConditionCopy" />
  </f7-page>
</template>

<script>
import BackendApi from '../components/backend-api.js'
import Order from '../models/order.js'
import DialogsMixin from '../mixins/dialogs.js'
import TaxCondition from '../components/taxcondition.vue'
import TaxConditionForm from '../pages/taxcondition-form.vue'

export default {
  name: "order-form",
  components: {
    'taxcondition': TaxCondition,
    'taxcondition-form': TaxConditionForm
  },
  mixins: [ DialogsMixin ], 
  data: function(){
    return{
        popupOpened: false,
        popupOpenedTaxForm: false,
        isUpdatingModel: false,
        agent: {
            agentId: parseInt(this.$f7route.query.agentId),
            agentName: this.$f7route.query.agentName,
            agentCode: this.$f7route.query.agentCode,
            salePointId: this.$f7route.query.salePointId,
            salePointName: this.$f7route.query.salePointName,
        },
        dependencies: {
            deliveryMethods: [],
            products: [],
            saleTypes: [],
            countryBankCodes: [],
        },
        selectedDeliveryMethod: null,
        selectedProduct: null,
        selectedSaleType: null,
        selectedTaxCondition: null,
        selectedTaxConditionCopy: null,
        selectedCountryBankCode: null,
        selectedBankCode: null,
        iban: null,
        mcc: null,
        workingRecord: {...Order}
    }
  },
  created: function(){
    var self = this;
    self.showDialog(null,true);
    self.loadDependencies();

    self.workingRecord.AgentId = self.agent.agentId;
    self.workingRecord.SalePointId = self.agent.salePointId;
  },
  computed: {
      canUpdate: function(){
          var self = this;
          return (self.selectedProduct != null && self.selectedDeliveryMethod != null && self.selectedSaleType != null);
      },
      showFreeMonths: function(){
          var self = this;
          return self.canUpdate && self.selectedSaleType.Id == 6;
      },
      showBankInfo: function(){
          var self = this;
          return self.canUpdate && self.selectedProduct.IsTerminal;
      },
      disableIBAN: function(){
          var self = this;
          return !(self.selectedBankCode != null && self.selectedBankCode.BankCodesId > 0);
      }
  },
  methods: {
    loadDependencies: function(){
        var self = this;
        BackendApi.getOrderRequisites(
            {"DeviceTerminalId": BackendApi.deviceId()},
            result => {
                if(result.data.Products.length == 0)
                {
                    self.showDialog("Não foram encontrados Produtos!", false);
                    return;
                }
                result.data.DeliveryMethods.forEach(value => { self.dependencies.deliveryMethods.push(value); });
                result.data.Products.forEach(value => { self.dependencies.products.push(value); });
                result.data.SaleTypes.forEach(value => { self.dependencies.saleTypes.push(value); });
                self.hideDialog();
            },
            error => {
                self.showDialog('Não foi possivel contactar a Pagaqui! '+error);
            }
        );

        BackendApi.getCountryBankCodes(
            {"DeviceTerminalId": BackendApi.deviceId()},
            result => {
                self.selectedCountryBankCode = null;
                if(result.data.CountryBankCodes.length == 0)
                {
                    self.hideDialog();
                    return;
                }
                result.data.CountryBankCodes.forEach(value => { self.dependencies.countryBankCodes.push(value); });
                self.selectedCountryBankCode = self.dependencies.countryBankCodes[0];
                self.hideDialog();
            },
            error => {
                self.showDialog('Não foi possivel contactar a Pagaqui! '+error);
            }
        );

        self.workingRecord.Iban = null;
    },
    getForm: function(){
      var self = this;

      // do form validation:
      self.$f7.input.validateInputs('item-input');
      if(document.getElementsByClassName('item-input-invalid').length > 0){
        self.showDialog('Existem campos mal preenchidos!', false);
        return;
      }

      if(!self.selectedProduct.AllowQuantityChange && self.selectedTaxCondition == null){
        self.showDialog('Existem campos mal preenchidos!', false);
        return;
      }

      if(self.selectedTaxCondition != null){
          self.workingRecord.TaxConditionId = self.selectedTaxCondition.TaxConditionId;
      }
      else{
          self.workingRecord.TaxConditionId = null;
      }

      if(!self.selectedBankCode || !self.workingRecord.Iban){
        self.showDialog('O Banco e IBAN são campos obrigatórios!', false);
        return;
      }
      else{
        self.workingRecord.BankCodeId = self.selectedBankCode.BankCodesId;
      }
      
      if(!self.mcc){
          self.showDialog('O MCC é obrigatório!', false);
        return;
      }
      else{
        self.selectedTaxConditionCopy.MCCVisaMC = self.mcc;  
      }
      
      return self.workingRecord;
    },
    createOrder: function(){
        var self = this;
        var order = self.getForm();

        if(order == null) return;
        var request = {
            DeviceTerminalId: BackendApi.deviceId(),
            Order: order,
            TaxCondition: self.selectedTaxConditionCopy
        }

        self.showDialog(null, true);
        BackendApi.createOrder(request,
            result => {
                if(result.data.Result != true)
                {
                    self.showDialog("Não foi possivel submeter a Ordem!");
                    return;
                }

                self.hasSubmited = true;
                self.showDialog("Ordem criada com sucesso!");
            },
            error => {
                self.showDialog('Não foi possivel contactar a Pagaqui! '+error);
            }
        )
    },
    updateModel: function() {
        var self = this;

        if(self.isUpdatingModel == true || !self.canUpdate) return;
        self.isUpdatingModel = true;
        try {
            self.workingRecord.ProductId = self.selectedProduct.Id;
            self.workingRecord.DeliveryMethodId = self.selectedDeliveryMethod.Id;
            self.workingRecord.SaleTypeId = self.selectedSaleType.Id;
            if(self.workingRecord.SaleTypeId.Id != 6) self.workingRecord.FreeMonths = 0;

            if(self.workingRecord.UnitValue < 0) self.workingRecord.UnitValue = self.selectedProduct.Price;

            var unitPrice = self.workingRecord.UnitValue;
            var unitVat = self.selectedProduct.VatValue;
            var deliveryValue = self.selectedDeliveryMethod.Price;
            var deliveryVat = self.selectedDeliveryMethod.VatValue;
            var quantity = self.workingRecord.Quantity;

            self.workingRecord.DeliveryValue = self.round(deliveryValue, 2);
            self.workingRecord.AmountWithoutVat = self.round((unitPrice * quantity) + deliveryValue, 2);
            self.workingRecord.AmountWithVat = self.round(((quantity * (unitPrice * (1+unitVat/100))) + (deliveryValue * (1+deliveryVat/100))), 2);
            self.workingRecord.TotalVatValue = self.round(((quantity * (unitPrice * (1+unitVat/100))) - (unitPrice * quantity)), 2);
        } catch (error) {
            alert(error);
        }

        self.isUpdatingModel = false;
    },
    productChanged: function(){
        var self = this;
        self.workingRecord.UnitValue = self.selectedProduct.Price;
        self.workingRecord.Quantity = 1;
        self.selectedTaxCondition = null;
        self.updateTaxForm();
        self.updateModel();
    },
    deliveryChanged: function(){
        var self = this;
        self.updateModel();
    },
    round: function(value, decimals){
        return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
    },
    closePopup: function(){
      this.popupOpened = false;
    },
    closePopupTaxForm: function(){
        var self = this;
        self.popupOpenedTaxForm = false;
        self.selectedTaxConditionCopy = self.$refs.taxform.getTaxForm();
    },
    validateIban: function(){
        var self = this;

        if(self.workingRecord.Iban == null || self.workingRecord.Iban.length == 0){
            return;
        }
        BackendApi.validateIban(
            {"DeviceTerminalId": BackendApi.deviceId(), "BankCodeId": self.selectedBankCode.BankCodesId, "IBAN": self.workingRecord.Iban},
            result => {
                if(result.data.Result != true)
                {
                    self.showDialog('O IBAN não é válido!');
                    return;
                }
                
                self.hideDialog();
            },
            error => {
                self.showDialog('Não foi possivel contactar a Pagaqui! '+error);
            }
        );
    },
    updateTaxForm: function(){
        var self = this;
        if(self.selectedTaxCondition != null){
            self.selectedTaxConditionCopy = JSON.parse(JSON.stringify(self.selectedTaxCondition));
        }
        else{
            self.selectedTaxConditionCopy = null;
        }
        
    }
  }
};
</script>

<style scoped>
.cardlist ul::before{
    background-color: transparent !important;
}

.cardlist ul::after{
    background-color: transparent !important;
}
.u-sidemargin{
    margin-left: 10px;
    margin-right: 10px;
}
</style>