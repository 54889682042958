export default {
  getToken () {
    return JSON.parse(localStorage.accessToken);
  },
  getIdToken () {
    return JSON.parse(localStorage.idToken);
  },
  logout () {
    delete localStorage.accessToken
    delete localStorage.idToken
    return true;
  },
  loggedIn () {
    return !!localStorage.accessToken && Math.floor(Date.now() / 1000) <= this.getIdToken().claims.exp;
  },
  onChange () {
  }
}