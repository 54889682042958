<template>
  <f7-popup class="demo-popup" :opened="popupOpened" @popup:closed="onFormClosed()">
    <f7-page v-if="popupOpened">
      <f7-navbar title="Adicionar imagens">
        <f7-nav-right>
          <f7-link popup-close>Fechar</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <!-- FORM -->
      <div class="list">
        <!-- SELECTOR -->
        <f7-card>
          <f7-card-content class="item-inner input-dropdown-wrap">
            <select name="categorieSelector" v-model="categoryId">
              <option :value="null" disabled>Escolha a Categoria</option>
              <option v-for="item in categories" :key="item.Id" :value="item.Id">{{item.Name}}</option>
            </select>
          </f7-card-content>
        </f7-card>

        <!-- Image FIELDS -->
        <ul v-if="documentFiles && documentFiles.length > 0">

          <li class="item-content item-input item-input-with-info">
            <div class="item-inner">
              <div class="item-title item-label">Nome do documento (PDF)</div>
              <div class="item-input-wrap">
                <input 
                  type="text" 
                  name="docName" 
                  maxlength="100" minlength="3" 
                  required 
                  validate 
                  v-model="documentName"
                  data-error-message="* Campo obrigatório"
                />
                <div class="item-input-info"></div>
              </div>
            </div>
          </li>

          <form-field v-for="item in documentFiles" :key="item.fieldId" v-bind:field="item" ref="fields" />
          <li class="item-content item-input" >
            <div class="item-inner c-flex-row">
              <span>
                <f7-button class="button-fill" iconMaterial="library_add" @click="addImage()"></f7-button>
              </span>
              <span>
                <f7-button class="button-fill" iconMaterial="remove_circle" @click="removeImage()" v-if="documentFiles.length > 1"></f7-button>
              </span>
            </div>
          </li>
        </ul>
        <ul v-if="canSave">
          <li class="item-content item-input" >
            <div class="item-inner">
              <f7-button 
                class="button-fill button-round" 
                iconMaterial="cloud_upload" 
                @click="$emit('form-save')">&nbsp;{{saveText}}</f7-button>
            </div>
          </li>
        </ul>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import BackendApi from '../components/backend-api.js'
import FormField from '../components/form-field.vue'

export default {
  name: "documentfiles-form",
  components: {
    'form-field': FormField
  },
  props: {
    saveText: String,
    popupOpened: Boolean,
    onClose: Function
  },
  data: function(){
    return {
      categoryId: null,
      documentName: "",
      documentFiles: [],
      categories: [],
    }
  },
  created: function()
  {
    var self = this;
    self.loadCategories();
    self.addImage();
  },
  computed: {
    canSave: function(){
      var self = this;
      return self.categoryId > 0 && self.documentFiles.length > 0;
    }
  },
  methods: {
    hasFiles: function(){
      var self = this;
      var fields = self.$refs.fields;
      for(var i = 0; i < fields.length; i++)
      {
        if (fields[i].field.FieldType=='image')
        {
          return true;
        }
      }
      return false;
    },
    getFiles: function(){
      var self = this;
      var fields = self.$refs.fields;
      var result = new Array();

      if (!fields || fields.length == 0)
      {
        return result;
      }
      
      for(var i = 0; i < fields.length; i++)
      {
        if (fields[i].field.FieldType!='image')
        {
          continue;
        }

        if (fields[i].fieldValue && fields[i].fieldValue.length > 0)
        {
            //var originalFile = fields[i].getFile();
            result.push({id: fields[i].field.CRMCampaignFieldId, file: fields[i].getFile()});
        }
      }

      return result;
    },
    getDocumentFilesInfo: function()
    {
      var self = this;
      var fields = self.$refs.fields;
      var result = { CategoryId: self.categoryId, FieldAnswers: [] };

      if (!fields || fields.length == 0)
      {
        return result;
      }

      for(var i = 0; i < fields.length; i++)
      {
        if (fields[i].field.FieldType == 'image')
        {
          continue;
        }
        result.FieldAnswers.push(fields[i].getField());
      }

      return result;
    },
    loadCategories: function(){
      var self = this;
      BackendApi.getCategories(
        {"DeviceTerminalId": BackendApi.deviceId()},
        result => {
          if (result.data && result.data.Result.length > 0)
          {
            self.categories = result.data.Result;
          }
          else
          {
            self.categories = [];
          }
        },
        () => {
          self.categories = [];
        }
      )
    },
    addImage: function(){
      var self = this;
      var nextId = 1 + self.documentFiles.length;
      self.documentFiles.push({
        "CRMCampaignFieldId": nextId,
        "DisplayName": "Imagem " + nextId,
        "FieldType": "image",
        "FieldIndex": nextId,
        "IsRequired": true
      });
    },
    removeImage: function(){
      var self = this;
      if(self.documentFiles.length == 1) return;
      self.documentFiles.pop();
    },
    resetForm: function(){
      var self = this;
      self.categoryId = null;
      self.documentFiles = [];
      self.addImage();
    },
    onFormClosed: function(){
      this.onClose();
      this.resetForm();
    }
  }
};
</script>

<style scoped>
  .c-flex-row{
    flex-direction: row !important;
    justify-content: space-around;
  }
</style>