export default
{
  AgentId: 0,
  SalePointId: 0,
  ProductId: 0,
  DeliveryMethodId: 0,
  SaleTypeId: 0,
  Quantity: 1,
  UnitValue: -1.0,
  DeliveryValue:0.0,
  AmountWithoutVat: 0.0,
  TotalVatValue: 0.0,
  AmountWithVat: 0.0,
  Observations: '',
  TaxConditionId: null,
  FreeMonths: 0,
  BankCodeId: null,
  Iban: null
}