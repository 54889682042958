import Home from './assets/vue/pages/home.vue';
import Prospects from './assets/vue/pages/prospects.vue';
import GeoPosition from './assets/vue/pages/geo-position.vue';
import UserCalendar from './assets/vue/pages/user-calendar.vue';
import Contacts from './assets/vue/pages/contacts.vue';
import Orders from './assets/vue/pages/orders.vue';
import Login from './assets/vue/pages/login.vue';

export default 
[
  {
    path: '/',
    component: Home,
    menu: {
      show: false,
      title: 'Home',
      icon: 'home'
    }
  },
  {
    path: '/geo-position',
    component: GeoPosition,
    menu: {
      show: true,
      title: 'Localizar ponto de venda',
      icon: 'add_location'
    }
  },
  {
    path: '/prospects',
    component: Prospects,
    menu: {
      show: true,
      title: 'Prospects/Agentes',
      icon: 'not_listed_location'
    }
  },
  {
    path: '/user-calendar',
    component: UserCalendar,
    menu: {
      show: true,
      title: 'Agenda',
      icon: 'today'
    }
  },
  {
    path: '/contacts',
    component: Contacts,
    menu: {
      show: false,
      title: 'Contatos',
      icon: 'today'
    }
  },
  {
    path: '/orders',
    component: Orders,
    menu: {
      show: false,
      title: 'Nova Encomenda',
      icon: 'today'
    }
  },
  {
    path: '/login',
    component: Login,
    menu: {
      show: false,
      title: 'Login',
      icon: 'person'
    }
  },
]


