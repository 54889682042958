import axios from 'axios'
import auth from '../../../auth.js'
import ImageTools from './ImageTools.js'

const imageTools = new ImageTools();

export default {
    __internal:{
        uri: function(url)
        {
            // eslint-disable-next-line
            return appSettings.API_LOCATION+url;
        },
        axioConfig: function(){
            return { 
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'multipart/form-data'
                },
                crossdomain: true,
            };
        },
        post: function(uri, requestObject, successCallback, errorCallback)
        {
            // eslint-disable-next-line
            requestObject.DeviceAppVersion = appSettings.VERSION;
            axios.post(this.uri(uri), requestObject, this.axioConfig().headers)
            .then(result => successCallback(result))
            .catch(error => errorCallback(error))
        }
    },
    userName: function(){
        return auth.getIdToken().claims.name;
    },
    userEmail: function(){
        return auth.getIdToken().claims.email;
    },
    loggedIn: function(){
        return auth.loggedIn();
    },
    logOut: function(){
        auth.logout();
    },
    deviceId: function()
    {
        return this.userEmail();
    },
    deviceAppVersion : function()
    {
        // eslint-disable-next-line
        return appSettings.VERSION;
    },
    deviceLogin: function(requestObject, successCallback, errorCallback)
    {
        return this.__internal.post('DeviceLogin', requestObject, successCallback, errorCallback);
    },
    getSalePointList: function(requestObject, successCallback, errorCallback)
    {
        this.__internal.post('SalePointListRequest', requestObject, successCallback, errorCallback);
    },
    sendGpsCoordinates: function(requestObject, successCallback, errorCallback)
    {
        this.__internal.post('SendGPSCoordinatesRequest', requestObject, successCallback, errorCallback);
    },
    getCRMAgent: function(requestObject, successCallback, errorCallback)
    {
        this.__internal.post('CRMAgentRequest', requestObject, successCallback, errorCallback);
    },
    getCRMAgentStatusList: function(requestObject, successCallback, errorCallback)
    {
        this.__internal.post('CRMAgentStatusList', requestObject, successCallback, errorCallback);
    },
    getProspect: function(requestObject, successCallback, errorCallback)
    {
        this.__internal.post('GetProspect', requestObject, successCallback, errorCallback);
    },
    getAgent: function(requestObject, successCallback, errorCallback)
    {
        this.__internal.post('AgentRequest', requestObject, successCallback, errorCallback);
    },
    getAgentByTin: function(requestObject, successCallback, errorCallback)
    {
        this.__internal.post('AgentByTinRequest', requestObject, successCallback, errorCallback);
    },
    getAgentTypes: function(requestObject, successCallback, errorCallback)
    {
        this.__internal.post('AgentTypesRequest', requestObject, successCallback, errorCallback);
    },
    getPostalCity: function(requestObject, successCallback, errorCallback)
    {
        this.__internal.post('PostalCity', requestObject, successCallback, errorCallback);
    },
    setCRMAgent: function(requestObject, successCallback, errorCallback)
    {
        this.__internal.post('SetCRMAgentRequest', requestObject, successCallback, errorCallback);
    },
    getCampaignList: function(requestObject, successCallback, errorCallback)
    {
        this.__internal.post('CampaignList', requestObject, successCallback, errorCallback);
    },
    getCampaignFields: function(requestObject, successCallback, errorCallback)
    {
        this.__internal.post('CampaignFields', requestObject, successCallback, errorCallback);
    },
    getPostalAddress: function(requestObject, successCallback, errorCallback)
    {
        this.__internal.post('PostalAddress', requestObject, successCallback, errorCallback);
    },
    saveProspectContact: function(requestObject, successCallback, errorCallback)
    {
        this.__internal.post('SaveContact', requestObject, successCallback, errorCallback);
    },
    getSchedule: function(requestObject, successCallback, errorCallback)
    {
        this.__internal.post('Schedule', requestObject, successCallback, errorCallback);
    },
    validateDevice: function(requestObject, successCallback, errorCallback)
    {
        this.__internal.post('ValidateDevice', requestObject, successCallback, errorCallback);
    },
    uploadFile: function(fileData, successCallback, errorCallback, options)
    {
        let formData = new FormData();
        
        formData.append(options.fileName, fileData);
        for (const [key, value] of Object.entries(options.params)) {
            formData.set(key, value);
        }
        // eslint-disable-next-line
        formData.set("DeviceAppVersion", appSettings.VERSION);
        axios.post(this.__internal.uri('UploadFile'), formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }})
            .then(result => successCallback(result))
            .catch(error => {
                console.log(error);
                errorCallback(error);
            })

        //this.__internal.post('UploadFile', fileData, successCallback, errorCallback);
    },
    getContactList: function(requestObject, successCallback, errorCallback)
    {
        this.__internal.post('GetContactList', requestObject, successCallback, errorCallback);
    },
    getOrderRequisites: function(requestObject, successCallback, errorCallback)
    {
        this.__internal.post('GetOrderRequisites', requestObject, successCallback, errorCallback);
    },
    createOrder: function(requestObject, successCallback, errorCallback)
    {
        this.__internal.post('CreateOrder', requestObject, successCallback, errorCallback);
    },
    getCalendarEventDetail: function(requestObject, successCallback, errorCallback)
    {
        this.__internal.post('GetCalendarEventDetail', requestObject, successCallback, errorCallback);
    },
    resolveContactStatus: function(requestObject, successCallback, errorCallback)
    {
        this.__internal.post('ResolveContactStatus', requestObject, successCallback, errorCallback);
    },
    insertComment: function(requestObject, successCallback, errorCallback)
    {
        this.__internal.post('InsertComment', requestObject, successCallback, errorCallback);
    },
    getCategories: function(requestObject, successCallback, errorCallback)
    {
        this.__internal.post('DocumentFileCategories', requestObject, successCallback, errorCallback);
    },
    uploadAgentFiles: function(files, successCallback, errorCallback, options)
    {
        // init form:
        let formData = new FormData();
        // eslint-disable-next-line
        formData.set("DeviceAppVersion", appSettings.VERSION);
        var promises = [];
        // resize image files:
        for(var i = 0; i < files.length; i++)
        {
            promises.push(imageTools.resize(files[i].file, options.maxDimensions));
        }
        
        Promise.all(promises).then(blobs => {
            for(var i = 0; i < blobs.length; i++)
            {
                formData.append(options.fileName + i, new File([blobs[i]], options.fileName + i, { type: "image/jpeg", }));
            }

            // add remaining fields to form:
            for (const [key, value] of Object.entries(options.params)) {
                formData.set(key, value);
            }

            // post to backend:
            axios.post(this.__internal.uri('UploadAgentFiles'), formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }})
                .then(result => successCallback(result))
                .catch(error => {
                    console.log(error);
                    errorCallback(error);
                })
        });
    },
    getCountryBankCodes: function(requestObject, successCallback, errorCallback)
    {
        return this.__internal.post('CountryBankCodes', requestObject, successCallback, errorCallback);
    },
    validateIban: function(requestObject, successCallback, errorCallback)
    {
        return this.__internal.post('ValidateIBAN', requestObject, successCallback, errorCallback);
    },
}