<template>
  
 <ul id="crmAgentFields">
   <form ref="crmAgentForm">
        <li class="item-content item-input item-input-with-info">
          <div class="item-inner">
            <div class="item-title item-label">Nome</div>
            <div class="item-input-wrap">
              <input 
                name="crmAgentName" 
                type="text" 
                :placeholder="'Nome'"
                v-model="workingRecord.Name"
                required 
                validate
                data-error-message="* Campo obrigatório">
              <span class="input-clear-button"></span>
              <div class="item-input-info"></div>
            </div>
          </div>
        </li>
        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">Tipo de prospect</div>
              <div class="item-input-wrap input-dropdown-wrap">
                <select v-model="workingRecord.AgentTypeId">
                  <option :value="null" disabled>Tipo de prospect</option>
                  <option v-for="item in agentTypes" :key="item.AgentTypeId" :value="item.AgentTypeId" >
                    {{ item.Name }}
                  </option>
                </select>
              </div>
          </div>
        </li>
        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">Estado</div>
              <div class="item-input-wrap input-dropdown-wrap">
                <select v-model="workingRecord.CRMAgentStatusId">
                  <option :value="null" disabled>Estado do prospect</option>
                  <option v-for="item in agentStatus" :key="item.Id" :value="item.Name" >
                    {{ item.Name }}
                  </option>
                </select>
              </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info">
          <div class="item-inner">
            <div class="item-title item-label">Código postal</div>
            <div class="item-input-wrap">
              <input 
                name="PostalCodePrefix" 
                pattern="[0-9]{4}-[0-9]{3}" 
                type="text" 
                maxlength="8"
                :placeholder="'0000-000'" 
                v-model="fullCP" 
                required 
                validate
                data-error-message="* Campo obrigatório">
              <input 
                name="PostalCodeCity" 
                type="text" 
                maxlength="50"
                :placeholder="'Localidade'" 
                required
                validate
                disabled
                v-model="workingRecord.PostalCodeCity"
                data-error-message="* Localidade não encontrada">
              <div class="item-input-info"></div>
            </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info">
          <div class="item-inner">
            <div class="item-title item-label">Morada</div>
            <div class="item-input-wrap">
              <input 
                name="Address" 
                type="text" 
                validate
                required
                :placeholder="addressPlaceholder" 
                v-model="workingRecord.Address"
                data-error-message="* Campo obrigatório">
              <span class="input-clear-button"></span>
              <div class="item-input-info"></div>
            </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info">
          <div class="item-inner">
            <div class="item-title item-label">Email</div>
            <div class="item-input-wrap">
              <input 
                name="Email" 
                type="email" 
                validate
                required
                :placeholder="'<nome>@email.pt'" 
                v-model="workingRecord.Email"
                data-error-message="* Email inválido">
              <span class="input-clear-button"></span>
              <div class="item-input-info"></div>
            </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info">
          <div class="item-inner">
            <div class="item-title item-label">Telefone</div>
            <div class="item-input-wrap">
              <input 
                name="Phone1" 
                pattern="^\+{0,1}[\d\s]+$" 
                type="tel"
                maxlength="16" 
                :placeholder="'Telefone'" 
                v-model="workingRecord.Phone1" 
                validate
                required
                data-error-message="* Numero inválido">
              <span class="input-clear-button"></span>
              <div class="item-input-info"></div>
            </div>
          </div>
        </li>
   </form>
        <li class="item-content item-input">
          <div class="item-inner">
            <f7-button 
              class="button-fill button-round" 
              iconMaterial="cloud_upload" 
              @click="$emit('form-save')">&nbsp;Criar prospect</f7-button>
          </div>
        </li>
      </ul>
</template>


<script>
import BackendApi from '../components/backend-api.js'
import FormField from '../components/form-field.vue'
import CrmAgentModel from '../models/crm-agent.js'

export default {
  name: "crmagent-form",
  components: {
    'form-field': FormField
  }, 
  props: {
    crmAgentName: String
  },
  data: function(){
    return{
      agentTypes: [],
      agentStatus: [],
      fullCP: null,
      addressPlaceholder: 'Morada',
      workingRecord: CrmAgentModel
    }
  },
  watch: {
    fullCP: function(value, oldValue){
      var self = this;

      if(value && value.length === 4 && oldValue.length < value.length)
      {
        self.fullCP = value + '-';
      }

      if(value && value.length < 4)
      {
        self.workingRecord.PostalCodeCity = null;
      }

      if(value && value.length === 8)
      {
        self.workingRecord.PostalCodePrefix = parseInt(value.substr(0, 4));
        self.workingRecord.PostalCodeSuffix = parseInt(value.substr(5, 3));

        BackendApi.getPostalCity(
          {"PostalCodePrefix": self.workingRecord.PostalCodePrefix ,"DeviceTerminalId": BackendApi.deviceId()},
          result => {
            if (result.data && result.data.CityName)
            {
              self.workingRecord.PostalCodeCity = result.data.CityName;
            } 
            else
            {
              self.workingRecord.PostalCodeCity = null;
            }
          },
          error => {
            self.workingRecord.PostalCodeCity = null;
          }
        )

        self.addressPlaceholder = "a pesquisar...";
        BackendApi.getPostalAddress(
          {"PostalCode": value,"DeviceTerminalId": BackendApi.deviceId()},
          result => {
            if (result.data && result.data.Address && result.data.Address.length > 0)
            {
              self.workingRecord.Address = result.data.Address;
            }
            self.addressPlaceholder = 'morada';
          },
          error => {
            //
            self.addressPlaceholder = 'morada';
          }
        )
      }
    }
  },
  created: function(){
    var self = this;
    console.log("Created!");
    Object.keys(self.workingRecord).forEach(function(key,index) {
      self.workingRecord[key] = '';
    });
    BackendApi.getAgentTypes(
      {"AgentCode": 0,"DeviceTerminalId": BackendApi.deviceId()},
      result => {
        self.agentTypes = result.data;
      },
      error => {
        self.hasSubmited = true; // force dialog close to navigate home
        self.showDialog('Não foi possivel contactar a Pagaqui! '+error);
      }
    );
    BackendApi.getCRMAgentStatusList(
      {"DeviceTerminalId": BackendApi.deviceId()},
      result => {
        self.agentStatus = result.data.Result;
      },
      error => {
        self.hasSubmited = true; // force dialog close to navigate home
        self.showDialog('Não foi possivel contactar a Pagaqui! '+error);
      }
    )
  },
  methods: {
    getForm: function(){

       var self = this;

      // do form validation:
      self.$f7.input.validateInputs('item-input');
      if(document.getElementsByClassName('item-input-invalid').length > 0){
        self.showDialog('Existem campos mal preenchidos!', false);
        return;
      }

      return self.workingRecord;
    },
  }
};
</script>